import {projectPayment} from "./index";

export const getProjectPayment = projectId => {
  return projectPayment.get(`/${projectId}`)
}

export const createProjectPayment = payload => {
  return projectPayment.post(`/`, payload)
}

export const putProjectPayment = payload => {
  return projectPayment.put(`/${payload.projectid}`, payload)
}

export const deleteProjectPayment = projectId => {
  return projectPayment.delete(`/${projectId}`)
}