import {
  INIT_PROJECT,
  INIT_CURRENT_PROJECT,
  ADD_PROJECT_REQUEST,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_ERROR,
  GET_LIST_PROJECT_REQUEST,
  GET_LIST_PROJECT_SUCCESS,
  GET_LIST_PROJECT_ERROR,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_ERROR,
  PUT_PROJECT_REQUEST,
  PUT_PROJECT_SUCCESS,
  PUT_PROJECT_ERROR,
  RESET_PROJECT_ERROR,
  SORT_PROJECT_BY_KEY
} from '../actions';

export const initProject = () => ({
  type: INIT_PROJECT,
  payload: {}
});

export const initCurrentProject = () => ({
  type: INIT_CURRENT_PROJECT,
  payload: {}
});

export const addProjectRequest = (project, history) => ({
  type: ADD_PROJECT_REQUEST,
  payload: { project, history }
});

export const addProjectSuccess = (newProject) => ({
  type: ADD_PROJECT_SUCCESS,
  payload : newProject
});

export const addProjectError = (message) => ({
  type: ADD_PROJECT_ERROR,
  payload: { message }
});

export const getListProjectRequest = (sort) => ({
  type: GET_LIST_PROJECT_REQUEST,
  payload: { sort }
});

export const getListProjectSuccess = (projects, message, sort) => ({
  type: GET_LIST_PROJECT_SUCCESS,
  payload: { projects, message, sort }
});

export const getListProjectError = (message) => ({
  type: GET_LIST_PROJECT_ERROR,
  payload: { message }
});

export const getProjectRequest = (projectId, history) => ({
  type: GET_PROJECT_REQUEST,
  payload: { projectId, history }
});

export const getProjectSuccess = (project) => ({
  type: GET_PROJECT_SUCCESS,
  payload: { project }
});

export const getProjectError = (message) => ({
  type: GET_PROJECT_ERROR,
  payload: { message }
});

export const putProjectRequest = (project) => ({
  type: PUT_PROJECT_REQUEST,
  payload: { project }
});

export const putProjectSuccess = (project) => ({
  type: PUT_PROJECT_SUCCESS,
  payload: { project }
});

export const putProjectError = (message) => ({
  type: PUT_PROJECT_ERROR,
  payload: { message }
});

export const deleteProjectError = () => ({
  type: RESET_PROJECT_ERROR,
});

export const sortProjectByKey = (key, pvArray) => ({
  type: SORT_PROJECT_BY_KEY,
  payload: { key, pvArray }
})
