import {
  INIT_TARGET_ITEM_ORIGIN_IMAGE,
  GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST,
  GET_TARGET_ITEM_ORIGIN_IMAGE_SUCCESS,
  GET_TARGET_ITEM_ORIGIN_IMAGE_ERROR,
  INIT_TARGET_ITEM_CV_IMAGE,
  GET_TARGET_ITEM_CV_IMAGE_REQUEST,
  GET_TARGET_ITEM_CV_IMAGE_SUCCESS,
  GET_TARGET_ITEM_CV_IMAGE_ERROR,
} from '../actions'

export const initTargetItemOriginImage = () => ({
  type: INIT_TARGET_ITEM_ORIGIN_IMAGE
})

export const getTargetItemOriginImageRequest = (imageId) => ({
  type: GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST,
  payload: { imageId }
})

export const getTargetItemOriginImageSuccess = (url) => ({
  type: GET_TARGET_ITEM_ORIGIN_IMAGE_SUCCESS,
  result: url
})

export const getTargetItemOriginImageError = (error) => ({
  type: GET_TARGET_ITEM_ORIGIN_IMAGE_ERROR,
  error: error.response.data
})

export const initTargetItemCvImage = () => ({
  type: INIT_TARGET_ITEM_CV_IMAGE
})

export const getTargetItemCvImageRequest = (imageId) => ({
  type: GET_TARGET_ITEM_CV_IMAGE_REQUEST,
  payload: { imageId }
})

export const getTargetItemCvImageSuccess = (url) => ({
  type: GET_TARGET_ITEM_CV_IMAGE_SUCCESS,
  result: url
})

export const getTargetItemCvImageError = (error) => ({
  type: GET_TARGET_ITEM_CV_IMAGE_ERROR,
  error: error.response.data
})
