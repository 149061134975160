import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
  GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST, getTargetItemCvImageError, getTargetItemCvImageSuccess
} from '../actions';
import {getTargetItemCvImage, getTargetItemOriginImage} from "../../api/targetManager";
import {getTargetItemOriginImageError, getTargetItemOriginImageSuccess} from "./actions";

/** 프로젝트의 ImageTarget 1개의 Origin Image Binary 정보 요청 **/
function *watchGetTargetItemOriginImage() {
  yield takeEvery(GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST, getTargetItemOriginImageSaga)
}

const getTargetItemOriginImageAsync = async (payload) => {
  const result = await getTargetItemOriginImage(payload)
  return result
}

function* getTargetItemOriginImageSaga({ payload }) {
  try {
    const result = yield call(getTargetItemOriginImageAsync, payload)
    const blob = new Blob([result.data])
    const url = URL.createObjectURL(blob)
    yield put(getTargetItemOriginImageSuccess(url))
  } catch (error) {
    yield put(getTargetItemOriginImageError(error))
  }
}

/** 프로젝트의 ImageTarget 1개의 Cv Image Binary 정보 요청 **/
function *watchGetTargetItemCvImage() {
  yield takeEvery(GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST, getTargetItemCvImageSaga)
}

const getTargetItemCvImageAsync = async (payload) => {
  const result = await getTargetItemCvImage(payload)
  return result
}

function* getTargetItemCvImageSaga({ payload }) {
  try {
    const result = yield call(getTargetItemCvImageAsync, payload)
    const blob = new Blob([result.data])
    const url = URL.createObjectURL(blob)
    yield put(getTargetItemCvImageSuccess(url))
  } catch (error) {
    yield put(getTargetItemCvImageError(error))
  }
}

export default function* targetItemImageSaga() {
  yield all([
    fork(watchGetTargetItemOriginImage),
    fork(watchGetTargetItemCvImage)
  ])
}
