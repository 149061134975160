import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import project from './project/reducer';
import editorProject from './editorProject/reducer';
// import targetList from './targetList/reducer';
// import targetItem from './targetItem/reducer';
// import targetRating from './targetRating/reducer';
import targetList from './userTargetList/reducer'
import targetItem from './userTargetItem/reducer'
import targetRating from './userTargetRating/reducer'
import targetItemMeta from './targetItemMeta/reducer'
import targetItemImage from './targetItemImage/reducer'


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  project,
  editorProject,
  targetList,
  targetItem,
  targetRating,
  targetItemMeta,
  targetItemImage
});

export default reducers;
