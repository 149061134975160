import {
  INIT_EDITOR_PROJECT,
  INIT_CURRENT_EDITOR_PROJECT,
  ADD_EDITOR_PROJECT_REQUEST,
  ADD_EDITOR_PROJECT_SUCCESS,
  ADD_EDITOR_PROJECT_ERROR,
  GET_LIST_EDITOR_PROJECT_REQUEST,
  GET_LIST_EDITOR_PROJECT_SUCCESS,
  GET_LIST_EDITOR_PROJECT_ERROR,
  GET_EDITOR_PROJECT_REQUEST,
  GET_EDITOR_PROJECT_SUCCESS,
  GET_EDITOR_PROJECT_ERROR,
  PUT_EDITOR_PROJECT_REQUEST,
  PUT_EDITOR_PROJECT_SUCCESS,
  PUT_EDITOR_PROJECT_ERROR,
  RESET_EDITOR_PROJECT_ERROR,
  SORT_EDITOR_PROJECT_BY_KEY
} from '../actions';

export const initEditorProject = () => ({
  type: INIT_EDITOR_PROJECT,
  payload: {}
});

export const initCurrentEditorProject = () => ({
  type: INIT_CURRENT_EDITOR_PROJECT,
  payload: {}
});

export const addEditorProjectRequest = (project) => ({
  type: ADD_EDITOR_PROJECT_REQUEST,
  payload: { project }
});

export const addEditorProjectSuccess = (newEditorProject) => ({
  type: ADD_EDITOR_PROJECT_SUCCESS,
  payload : newEditorProject
});

export const addEditorProjectError = (message) => ({
  type: ADD_EDITOR_PROJECT_ERROR,
  payload: { message }
});

export const getListEditorProjectRequest = (sort) => ({
  type: GET_LIST_EDITOR_PROJECT_REQUEST,
  payload: { sort }
});

export const getListEditorProjectSuccess = (projects, message, sort) => ({
  type: GET_LIST_EDITOR_PROJECT_SUCCESS,
  payload: { projects, message, sort }
});

export const getListEditorProjectError = (message) => ({
  type: GET_LIST_EDITOR_PROJECT_ERROR,
  payload: { message }
});

export const getEditorProjectRequest = (projectId, history) => ({
  type: GET_EDITOR_PROJECT_REQUEST,
  payload: { projectId, history }
});

export const getEditorProjectSuccess = (project) => ({
  type: GET_EDITOR_PROJECT_SUCCESS,
  payload: { project }
});

export const getEditorProjectError = (message) => ({
  type: GET_EDITOR_PROJECT_ERROR,
  payload: { message }
});

export const putEditorProjectRequest = (project) => ({
  type: PUT_EDITOR_PROJECT_REQUEST,
  payload: { project }
});

export const putEditorProjectSuccess = (project) => ({
  type: PUT_EDITOR_PROJECT_SUCCESS,
  payload: { project }
});

export const putEditorProjectError = (message) => ({
  type: PUT_EDITOR_PROJECT_ERROR,
  payload: { message }
});

export const deleteEditorProjectError = () => ({
  type: RESET_EDITOR_PROJECT_ERROR,
});

export const sortEditorProjectByKey = (key, pvArray) => ({
  type: SORT_EDITOR_PROJECT_BY_KEY,
  payload: { key, pvArray }
})
