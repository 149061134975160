import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
  GET_TARGET_ITEM_META_REQUEST,
  UPDATE_TARGET_ITEM_META_TITLE_REQUEST,
  getTargetItemMetaError,
  getTargetItemMetaSuccess,
  updateTargetItemMetaTitleError,
  updateTargetItemMetaTitleSuccess
} from '../actions';
import {getTargetItemMeta, updateTargetItemMetaTitle} from "../../api/targetManager";

/** 프로젝트의 ImageTarget 1개의 Meta 정보 요청 **/
function *watchGetTargetItemMeta() {
  yield takeEvery(GET_TARGET_ITEM_META_REQUEST, getTargetItemMetaSaga)
}
const getTargetItemMetaAsync = async (payload) => {
  const result = await getTargetItemMeta(payload)
  return result
}
function* getTargetItemMetaSaga({ payload }) {
  try {
    const result = yield call(getTargetItemMetaAsync, payload)
    yield put(getTargetItemMetaSuccess(result))
  } catch (error) {
    yield put(getTargetItemMetaError(error))
  }
}

/** 프로젝트의 ImageTarget 1개의 Meta 타이틀 정보 수정 요청 **/
function* watchUpdateTargetItemMetaTitle() {
  yield takeEvery(UPDATE_TARGET_ITEM_META_TITLE_REQUEST, updateTargetItemMetaTitleSaga)
}
const updateTargetItemMetaTitleAsync = async (payload) => {
  const result = await updateTargetItemMetaTitle(payload)
  if(result) return payload.title
}
function* updateTargetItemMetaTitleSaga({ payload }) {
  try {
    const result = yield call(updateTargetItemMetaTitleAsync, payload)
    yield put(updateTargetItemMetaTitleSuccess(result))
  } catch (error) {
    yield put(updateTargetItemMetaTitleError(error))
  }
}

export default function* targetItemSaga() {
  yield all([
    fork(watchGetTargetItemMeta),
    fork(watchUpdateTargetItemMetaTitle)
  ])
}
