import {
  INIT_TARGET_ITEM_META,
  GET_TARGET_ITEM_META_REQUEST,
  GET_TARGET_ITEM_META_SUCCESS,
  GET_TARGET_ITEM_META_ERROR,
  UPDATE_TARGET_ITEM_META_TITLE_REQUEST,
  UPDATE_TARGET_ITEM_META_TITLE_SUCCESS,
  UPDATE_TARGET_ITEM_META_TITLE_ERROR,
} from '../actions'

export const initialState = {
  item: {},
  isLoading: false,
  error: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_TARGET_ITEM_META:
      return initialState
    case GET_TARGET_ITEM_META_REQUEST:
      return {...state, isLoading: true, error: ''}
    case GET_TARGET_ITEM_META_SUCCESS:
      return { item: {...action.result}, isLoading: false, error: ''}
    case GET_TARGET_ITEM_META_ERROR:
      return {...state, isLoading: false, error: action.error}
    case UPDATE_TARGET_ITEM_META_TITLE_REQUEST:
      return {...state, isLoading: true, error: ''}
    case UPDATE_TARGET_ITEM_META_TITLE_SUCCESS:
      state.item.title = action.result.title
      return {...state, isLoading: false, error: ''}
    case UPDATE_TARGET_ITEM_META_TITLE_ERROR:
      return {...state, isLoading: false, error: action.error}
    default:
      return {...state}
  }
}
