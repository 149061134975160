import axios from 'axios'
import setInterceptors from './interceptors'

const { REACT_APP_API_BASE, REACT_APP_AUTH_API_BASE, REACT_APP_TARGET_API_BASE } = process.env

/** Letsee backend API 주소를 base로 하는 Axios instance 반환  **/
const createInstanceWithEndPoint = url => {
	let instance = axios.create({
		baseURL: `${REACT_APP_API_BASE}${url}`,
	})
	return setInterceptors(instance)
}

/** Letsee TargetManager backend API 주소를 base로 하는 Axios instance 반환  **/
const createInstanceWithEndPointTM = url => {
	let instance = axios.create({
		baseURL: `${REACT_APP_TARGET_API_BASE}${url}`,
	})
	return setInterceptors(instance)
}

/** Letsee Auth backend API 주소를 base로 하는 Axios instance 반환 **/
const createInstanceWithEndPointApp = url => {
	let instance = axios.create({
		baseURL: `${REACT_APP_AUTH_API_BASE}${url}`,
	})
	return setInterceptors(instance)
}

export const app = createInstanceWithEndPointApp(`/app`)
export const users = createInstanceWithEndPoint('/users')
export const siteInfo = createInstanceWithEndPoint('/siteinfo')
export const projects = createInstanceWithEndPoint('/projects')
export const editorProjects = createInstanceWithEndPoint('/editor-projects')
export const projectPayment = createInstanceWithEndPoint('/project-payments')
export const payment = createInstanceWithEndPoint('/payment')
export const paymentComplete = createInstanceWithEndPoint('/payment-complete')
export const paymentHistory = createInstanceWithEndPoint('/payment-histories')
export const subscriptions = createInstanceWithEndPoint('/subscriptions')
export const viewCount = createInstanceWithEndPoint('/app/view-count')
export const targetManager = createInstanceWithEndPointTM(`/target-manager/projects`)
export const targetManagerUser = createInstanceWithEndPointTM(`/target-manager`)
export const emailCheck = createInstanceWithEndPointTM('/email-check')
export const contactUs = createInstanceWithEndPointTM('/contact-us')
export const fbxToGlb = createInstanceWithEndPointTM('/editor/fbx2glb')
