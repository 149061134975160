import {targetManagerUser} from "./index";

/** 계정의 image-target meta 정보 요청 **/
export const getUserTargetItemList = payload => {
  const { page, pageSize, search} = payload
  return targetManagerUser.get(`/images-meta?page=${page}&pageSize=${pageSize}${search ? '&search=' + search : ''}`)
}

/** 계정의 image-target meta 정보 요청 **/
export const getUserTargetItemImage = imageId => {
  return targetManagerUser.get(`/images/origin/${imageId}`, {responseType: 'arraybuffer'})
}

/** 계정의 ImageTarget 이름 바꾸기 **/
export const updateUserTargetItemTitle = payload => {
  const { imageId, title} = payload
  return targetManagerUser.put(`/images/${imageId}/title`, {title})
}

/** 계정의 ImageTarget 제거 **/
export const deleteUserTargetItem = payload => {
  const { imageId } = payload
  return targetManagerUser.delete(`/images/${imageId}`)
}

/** 계정의 ImageTarget rating 불러오기 **/
export const getUserTargetRating = payload => {
  const { formData} = payload
  return targetManagerUser.post(`/rating`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
}
/** 계정의 ImageTarget 업로드 **/
export const uploadUserTargetRating = payload => {
  const { formData, rating, width} = payload
  return targetManagerUser.post(`/images`, formData, {headers: {'Content-Type': 'multipart/form-data', 'rating': rating, 'width':  width}})
}
