export const defaultLocale = "en";

export const localeOptions = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "ko",
    name: "한국어",
  },
];