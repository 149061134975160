import {users} from "./index";

export const registerUser = payload => {
  return users.post('/', payload)
}

export const getUserInfo = () => {
  return users.get()
}

export const putUserInfo = payload => {
  return users.put('/', payload)
}

export const login = payload => {
  return users.post('/login/', payload)
}

export const sendAuthMail = (html, token) => {
  return users.post('/send-mail/', html, { headers: { 'Authorization': token } })
}

export const forgotPassword = email => {
  return users.post('/forgot-password', {email})
}

export const resetPassword = payload => {
  return users.post('/reset-password', payload)
}

export const sendForgotMail = html => {
  return users.post('/send-forgot-email', html)
}

export const checkAuthMail = key => {
  return users.post('/confirm-email', {key})
}

export const checkPassword = password => {
  return users.post('/check-password', {password})
}

export const checkLastResetPasswordKey = payload => {
  return users.post('/check-token', payload)
}