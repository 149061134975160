import {projects} from "./index";

export const getListProject = () => {
	return projects.get()
}

export const createProject = payload => {
	return projects.post('', payload)
}

export const getProject = projectId => {
	return projects.get(projectId)
}

export const putProject = payload => {
	return projects.put(`/${payload.id}`, payload)
}

export const deleteProject = projectId => {
	return projects.delete(projectId)
}

export const getCustomPayment = projectId => {
	return projects.get(`/payment/${projectId}`)
}
