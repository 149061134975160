import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  CHECK_USER_REQUEST,
  CHECK_USER_SUCCESS,
  CHECK_USER_ERROR,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  RESET_PASSWORD_VALIDATE,
  DELETE_AUTH_ERROR,
  RESET_USER_INFO
} from '../actions';

export const loginRequest = (user, history, link) => ({
  type: LOGIN_USER_REQUEST,
  payload: { user, history, link }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});
export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});
export const checkUserRequest = (history) => ({
  type: CHECK_USER_REQUEST,
  payload: { history }
});
export const checkUserSuccess = (userInfo) => ({
  type: CHECK_USER_SUCCESS,
  payload: userInfo
});
export const checkUserError = (message) => ({
  type: CHECK_USER_ERROR,
  payload: { message }
});
export const registerUserRequest = (user, history, t) => ({
  type: REGISTER_USER_REQUEST,
  payload: { user, history, t}
});
export const registerUserSuccess = () => ({
  type: REGISTER_USER_SUCCESS,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
});
export const resetPasswordValidate = (user) => ({
  type: RESET_PASSWORD_VALIDATE,
  payload: user
});
export const deleteAuthError = () => ({
  type: DELETE_AUTH_ERROR,
});
export const resetUserInfo = ()=> ({
  type: RESET_USER_INFO
});

