import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { defaultLocale } from '../constants/locale';
import resources from '../lang'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // resources would be bundled with webpack
    resources,
    fallbackLng: defaultLocale,
    // debug: process.env.NODE_ENV !== 'production',
    keySeparator: false, // char to separate keys (if working with a flat json, it's recommended to set keySeparator to false)
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    whitelist: Object.keys(resources)
  });

export default i18n;
