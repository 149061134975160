import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  CHECK_USER_REQUEST,
  CHECK_USER_SUCCESS,
  CHECK_USER_ERROR,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  RESET_PASSWORD_VALIDATE,
  DELETE_AUTH_ERROR,
  RESET_USER_INFO
} from '../actions';

const INIT_STATE = {
  user: JSON.parse(sessionStorage.getItem("userinfo")),
  userInfo: [],
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  isRegistered: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          user_id: action.payload.user_id,
          user_email: action.payload.user_email,
          token: action.payload.token
        },
        userInfo: action.payload.userInfo,
        error: action.payload.message
      };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, user: '', error: action.payload.message };
    case LOGOUT_USER:
      return { ...state, loading: false, user: null, userInfo: [], error: ''};
    case CHECK_USER_REQUEST:
      return { ...state, loading: true, error: '' };
    case CHECK_USER_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload.userInfo, error: '' };
    case CHECK_USER_ERROR:
      return { ...state, loading: false, user: '', userInfo: [], error: action.payload.message};
    case REGISTER_USER_REQUEST:
      return { ...state, loading: true, isRegistered: false, error: '' };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, isRegistered: true, error: '' };
    case REGISTER_USER_ERROR:
      return { ...state, loading: false, isRegistered: false, user: '', error: action.payload.message };
    case RESET_PASSWORD_VALIDATE :
      return { ...state, loading: false, userInfo: action.payload.userInfo, error: action.payload.message };
    case DELETE_AUTH_ERROR:
      return { ...state, error: '' };
    case RESET_USER_INFO:
      return INIT_STATE
    default:
      return { ...state };
  }
}
