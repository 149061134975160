import {targetManager, targetManagerUser} from "./index";

/** 프로젝트의 image-target meta 정보 요청 **/
export const getTargetItemList = payload => {
  const {projectId, page, pageSize, search} = payload
  return targetManager.get(`/${projectId}/images-meta?page=${page}&pageSize=${pageSize}${search ? '&search=' + search : ''}`)
}

/** 프로젝트의 ImageTarget 이름 바꾸기 **/
export const updateTargetItemTitle = payload => {
  const {projectId, imageId, title} = payload
  return targetManager.put(`/${projectId}/images/${imageId}/title`, {title})
}

/** 프로젝트의 ImageTarget 제거 **/
export const deleteTargetItem = payload => {
  const {projectId, imageId} = payload
  return targetManager.delete(`/${projectId}/images/${imageId}`)
}

/** 프로젝트의 ImageTarget rating 불러오기 **/
export const getTargetRating = payload => {
  const {projectId, formData} = payload
  return targetManager.post(`/${projectId}/rating`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
}
/** 프로젝트의 ImageTarget 업로드 **/
export const uploadTargetRating = payload => {
  const {projectId, formData, rating, width} = payload
  return targetManager.post(`/${projectId}/images`, formData, {headers: {'Content-Type': 'multipart/form-data', 'rating': rating, 'width':  width}})
}

/** 프로젝트의 ImageTarget 1개의 Meta 정보 요청 **/
export const getTargetItemMeta = payload => {
  const { imageId } = payload
  return targetManagerUser.get(`/images-meta/${imageId}`)
}

/** 프로젝트의 ImageTarget 1개의 Meta 타이틀 수정 요청 **/
export const updateTargetItemMetaTitle = payload => {
  const {imageId, title} = payload
  return targetManagerUser.put(`/images/${imageId}/title`, {title})
}

/** 프로젝트의 ImageTarget 1개의 Origin Image Binary 정보 요청 **/
export const getTargetItemOriginImage = payload => {
  const { imageId } = payload
  return targetManagerUser.get(`/images/origin/${imageId}`, {responseType: 'arraybuffer'})
}

/** 프로젝트의 ImageTarget 1개의 Cv Image Binary 정보 요청 **/
export const getTargetItemCvImage = payload => {
  const { imageId } = payload
  return targetManagerUser.get(`/images/cv/${imageId}`, {responseType: 'arraybuffer'})
}
