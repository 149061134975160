import {
  INIT_EDITOR_PROJECT,
  INIT_CURRENT_EDITOR_PROJECT,
  ADD_EDITOR_PROJECT_REQUEST,
  ADD_EDITOR_PROJECT_SUCCESS,
  ADD_EDITOR_PROJECT_ERROR,
  GET_LIST_EDITOR_PROJECT_REQUEST,
  GET_LIST_EDITOR_PROJECT_SUCCESS,
  GET_LIST_EDITOR_PROJECT_ERROR,
  GET_EDITOR_PROJECT_REQUEST,
  GET_EDITOR_PROJECT_SUCCESS,
  GET_EDITOR_PROJECT_ERROR,
  PUT_EDITOR_PROJECT_REQUEST,
  PUT_EDITOR_PROJECT_SUCCESS,
  PUT_EDITOR_PROJECT_ERROR,
  RESET_EDITOR_PROJECT_ERROR,
  SORT_EDITOR_PROJECT_BY_KEY
} from '../actions';
import {sortArrayByKey} from "../../helpers/Utils";

const INIT_STATE = {
  projects: [],
  current_project: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_EDITOR_PROJECT :
      return { ...state, loading: false, projects: [], current_project: {}, error: '' };
    case INIT_CURRENT_EDITOR_PROJECT :
      return { ...state, loading: false, current_project: {}, error: '' };
    case ADD_EDITOR_PROJECT_REQUEST:
      return { ...state, loading: true, error: '' };
    case ADD_EDITOR_PROJECT_SUCCESS:
      return { ...state, loading: false, error: '', projects: [action.payload, ...state.projects] };
    case ADD_EDITOR_PROJECT_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case GET_LIST_EDITOR_PROJECT_REQUEST:
      return { ...state, loading: true, projects: [], error: '' };
    case GET_LIST_EDITOR_PROJECT_SUCCESS:
      const { projects, message, sort } = action.payload
      return { ...state, loading: false, projects: sortArrayByKey(projects, sort), error: message };
    case GET_LIST_EDITOR_PROJECT_ERROR:
      return { ...state, loading: false, projects: [], error: action.payload.message };
    case GET_EDITOR_PROJECT_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_EDITOR_PROJECT_SUCCESS:
      return { ...state, loading: false, current_project: action.payload.project, error: '' };
    case GET_EDITOR_PROJECT_ERROR:
      return { ...state, loading: false, current_project: {}, error: action.payload.message };
    case PUT_EDITOR_PROJECT_REQUEST:
      return { ...state, loading: true, error: '' };
    case PUT_EDITOR_PROJECT_SUCCESS:
      const updatedProjects = [...state.projects].map(project => project._id === action.payload.project._id ? action.payload.project : project)
      return { ...state, loading: false, projects: updatedProjects, current_project: action.payload.project};
    case PUT_EDITOR_PROJECT_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case RESET_EDITOR_PROJECT_ERROR:
      return { ...state, error: '' };
    case SORT_EDITOR_PROJECT_BY_KEY:
      const { key, pvArray } = action.payload
      return { ...state, projects: sortArrayByKey(state.projects, key, pvArray)}
    default:
      return { ...state };
  }
}

