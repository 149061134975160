import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_TARGET_LIST_REQUEST,
  GET_TARGET_LIST_SUCCESS,
  GET_TARGET_LIST_ERROR
} from '../actions';

import {viewErrorByEnv} from "../../helpers/Utils";
import {getUserTargetItemList} from "../../api/userTargetManager";
import NoImgTargetImg from '../../assets/img/noimg-target.svg'

export function* watchGetTargetList() {
  yield takeEvery(GET_TARGET_LIST_REQUEST, getTargetListSaga);
}

/**
 * 프로젝트의 타겟 리스트를 불러옴
 * @param payload
 * @returns {Promise<*>}
 */
const getTargetListAsync = async (payload) => {
  const token = JSON.parse(sessionStorage.getItem("userinfo")).token;
  try {
    const responseData = await getUserTargetItemList(payload)
    const list = responseData.data.data
    const imagesArray = list.filter(el => Boolean(el.image_download_url && el.image_download_url.includes(String('/target-manager'))))
    const imagesAxiosArray = imagesArray.map(element => {
      if(!element.image_download_url) return
      return axios.get(element.image_download_url, { headers: { 'Authorization': token }, responseType: 'arraybuffer' })
    })
    const responseImageData = await axios.all(imagesAxiosArray)
    list.map(el => {
      const imageIndex = imagesArray.findIndex(imageEl => imageEl.image_id === el.image_id)
      if(imageIndex !== -1) {
        const image = btoa(
          new Uint8Array(responseImageData[imageIndex].data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        el.image = `data:${responseImageData[imageIndex].headers['content-type'].toLowerCase()};base64,${image}`
      } else {
        el.image = NoImgTargetImg
      }
      return el
    })
    responseData.data.data = list
    return responseData
  } catch(error) {
    viewErrorByEnv(error)
  }
}

/**
 * 프로젝트의 타겟 리스트를 불러오고 Store로 dispatch
 * @param payload
 */
function* getTargetListSaga({payload}) {
  try {
    const result = yield call(getTargetListAsync, payload)
    yield put({
      type: GET_TARGET_LIST_SUCCESS,
      result: result.data
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: GET_TARGET_LIST_ERROR,
      error: error.response ? error.response.data : "error"
    })
  }
}

export default function* targetListSaga() {
  yield all([
    fork(watchGetTargetList)
  ])
}
