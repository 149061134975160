import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_PROJECT_REQUEST,
  GET_LIST_PROJECT_REQUEST,
  GET_PROJECT_REQUEST,
  PUT_PROJECT_REQUEST,
} from '../actions';

import {
  initProject,
  addProjectSuccess,
  addProjectError,
  getListProjectSuccess,
  getListProjectError,
  getProjectSuccess,
  getProjectError,
  putProjectSuccess,
  putProjectError,
} from './actions';
import {createProject, getListProject, getProject, putProject} from "../../api/projects";
import {viewErrorByEnv} from "../../helpers/Utils";
import {createProjectPayment} from "../../api/projectPayment";

export function* watchAddProject() {
  yield takeEvery(ADD_PROJECT_REQUEST, addProjectWithValuesSaga);
}

const addProjectAsync = async (values) => {
  let createInfo = {
    "title": values.title,
    "domain": values.domain,
  };
  /**
   * 프로젝트 생성하는 비동기 axios 함수.
   *
   * 1. LD 프로젝트 레코드를 생성하는 API 호출
   * 2. 프로젝트 생성후 projectPayment, paymentHistory 레코드를 생성하는 API 호출
   */
    const projectResponse = await createProject(createInfo)
    // 프로젝트 생성이 성공했을시 projectPayment, paymentHistory 레코드를 생성하는 API 호출.
    let Project = {};
    if (projectResponse.data.success) {
      console.warn(projectResponse.data)
      let data = {
        project_id: projectResponse.data.project.id,
        payment_id: projectResponse.data.project.paymentid,
        app_id: projectResponse.data.project.appId,
      };
      
      console.warn(data)
      
      const projectPaymentResponse = await createProjectPayment(data);
      if (projectPaymentResponse.data.success) {
        Project.message = false;
        Object.assign(Project, projectResponse.data.project)
      } else {
        Project.message = projectResponse.data.error;
      }
    } else {
      Project.message = projectResponse.data.error;
    }
  return Project;
};

function* addProjectWithValuesSaga({ payload }) {
  const { project, history } = payload;
  try {
    const Project = yield call(addProjectAsync, project);
    if (!Project.message) {
      Project.viewcount = 0
      Project.maxviewcount = 1000
      yield put(addProjectSuccess(Project));
      // history.push('/project/workspace')
    } else {
      yield put(addProjectError(Project.message));

    }
  } catch (error) {
    if(error.response.data) {
      yield put(addProjectError(error.response.data.error))
    } else {
      viewErrorByEnv(error)
    }
  }
}

export function* watchGetListProject() {
  yield takeEvery(GET_LIST_PROJECT_REQUEST, getListProjectSaga);
}

const getListProjectAsync = async () => {
  const response = await getListProject()
  let Projects = {};
  if(response.data.success) {
    Projects.message = false;
    Projects.projects = response.data.projects;
  } else {
    Projects.message = response.data.error;
  }
  return Projects
};

function* getListProjectSaga({ payload }) {
  try {
    const Projects = yield call(getListProjectAsync);
    yield put(getListProjectSuccess(Projects.projects, Projects.message, payload?.sort));
  } catch (error) {
    if(error.response.data) {
      yield put(getListProjectError(error.response.data.error))
    } else {
      viewErrorByEnv(error)
    }

  }
}

export function* watchGetProjectById() {
  yield takeEvery(GET_PROJECT_REQUEST, getProjectByIdSaga);
}

const getProjectByIdAsync = async (projectId) => {
  const response = await getProject(projectId)
  let Project = {};
  if(response.data.success) {
    response.success ? Project.message = false : Project.message = response.error;
    Project.project = response.data.project;
  } else {
    Project.message = response.data.error;
  }
  return Project
};

function* getProjectByIdSaga({ payload }) {
  const { projectId, history } = payload;
  const user_id = JSON.parse(sessionStorage.getItem("userinfo")).user_id

  try {
    const Project = yield call(getProjectByIdAsync, projectId);
    if (!Project.message) {
      if (Project.project.users.find(x => {
        return x.user !== user_id || x.permissions.includes('pendding')})
      ) {
        yield put(initProject())
        yield put(getProjectError('permissionError'));
        history.push('/')
      } else {
        yield put(getProjectSuccess(Project.project));
      }
    } else {
      yield put(initProject())
      yield put(getProjectError(Project.message));
      history.push('/')
    }
  } catch (error) {
    yield put(initProject())
    if(error.response.data) {
      yield put(getProjectError(error.response.data.error));
    } else {
      viewErrorByEnv(error)
    }
    history.push('/')
  }
}

export function* watchPutProject() {
  yield takeEvery(PUT_PROJECT_REQUEST, putProjectSaga);
}

const putProjectAsync = async (projectInfo) => {
  let project = {};
    const response = await putProject(projectInfo)
    if(response.data.success) {
      project.project = response.data.project;
      project.message = false
    } else {
      project.message = response.data.error;
    }
  return project;
};

function* putProjectSaga({payload}) {
  const {project} = payload
  try {
    const result = yield call(putProjectAsync, project);
    if (!result.message) {
      yield put(putProjectSuccess(result.project));
    } else {
      yield put(putProjectError(result.message));
    }
  } catch (error) {
    if(error.response.data) {
      yield put(putProjectError(error.response.data.error));
    } else {
      viewErrorByEnv(error)
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAddProject),
    fork(watchGetListProject),
    fork(watchGetProjectById),
    fork(watchPutProject),
  ]);
}
