import {
  INIT_TARGET_ITEM_ORIGIN_IMAGE,
  GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST,
  GET_TARGET_ITEM_ORIGIN_IMAGE_SUCCESS,
  GET_TARGET_ITEM_ORIGIN_IMAGE_ERROR,
  INIT_TARGET_ITEM_CV_IMAGE,
  GET_TARGET_ITEM_CV_IMAGE_REQUEST,
  GET_TARGET_ITEM_CV_IMAGE_SUCCESS,
  GET_TARGET_ITEM_CV_IMAGE_ERROR,
} from '../actions'

export const initialState = {
  origin: {
    url: '',
    isLoading: false,
    error: {}
  },
  cv: {
    url: '',
    isLoading: false,
    error: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_TARGET_ITEM_ORIGIN_IMAGE:
      return { ...state, origin: initialState.origin }
    case GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST:
      return { ...state, origin: {...state.origin, isLoading: true, error: ''}}
    case GET_TARGET_ITEM_ORIGIN_IMAGE_SUCCESS:
      return { ...state, origin: { url: action.result, isLoading: false, error: ''}}
    case GET_TARGET_ITEM_ORIGIN_IMAGE_ERROR:
      return { ...state, origin: {...state.origin, isLoading: false, error: action.error}}
    case INIT_TARGET_ITEM_CV_IMAGE:
      return { ...state, cv: initialState.cv }
    case GET_TARGET_ITEM_CV_IMAGE_REQUEST:
      return { ...state, cv: {...state.cv, isLoading: true, error: ''}}
    case GET_TARGET_ITEM_CV_IMAGE_SUCCESS:
      return { ...state, cv: { url: action.result, isLoading: false, error: ''}}
    case GET_TARGET_ITEM_CV_IMAGE_ERROR:
      return { ...state, cv: {...state.cv, isLoading: false, error: action.error}}
    default:
      return {...state}
  }
}
