export default instance => {
  instance.interceptors.request.use(request => {
      return {
        ...request,
        headers: {
          ...request.headers,
          'Authorization': sessionStorage.getItem('userinfo') ? JSON.parse(sessionStorage.getItem('userinfo')).token : request.headers.Authorization
        }
      }
    }
  )
  instance.interceptors.response.use(
  response => response,
  error => {
      console.log('axios error', error)
      return Promise.reject(error)
    }
  )
  return instance
}