/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const CHECK_USER_REQUEST = "CHECK_USER_REQUEST";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const CHECK_USER_ERROR = "CHECK_USER_ERROR";
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const RESET_PASSWORD_VALIDATE = "RESET_PASSWORD_VALIDATE";
export const DELETE_AUTH_ERROR = "DELETE_AUTH_ERROR";
export const RESET_USER_INFO = "RESET_USER_INFO";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* PROJECT */
export const INIT_PROJECT = "INIT_PROJECT";
export const INIT_CURRENT_PROJECT = "INIT_CURRENT_PROJECT";
export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";
export const GET_LIST_PROJECT_REQUEST = "GET_LIST_PROJECT_REQUEST";
export const GET_LIST_PROJECT_SUCCESS = "GET_LIST_PROJECT_SUCCESS";
export const GET_LIST_PROJECT_ERROR = "GET_LIST_PROJECT_ERROR";
export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";
export const PUT_PROJECT_REQUEST = "PUT_PROJECT_REQUEST";
export const PUT_PROJECT_SUCCESS = "PUT_PROJECT_SUCCESS";
export const PUT_PROJECT_ERROR = "PUT_PROJECT_ERROR";
export const RESET_PROJECT_ERROR = "RESET_PROJECT_ERROR";
export const SORT_PROJECT_BY_KEY = "SORT_PROJECT_BY_KEY";

/* EDITOR PROJECT */
export const INIT_EDITOR_PROJECT = "INIT_EDITOR_PROJECT";
export const INIT_CURRENT_EDITOR_PROJECT = "INIT_CURRENT_EDITOR_PROJECT";
export const ADD_EDITOR_PROJECT_REQUEST = "ADD_EDITOR_PROJECT_REQUEST";
export const ADD_EDITOR_PROJECT_SUCCESS = "ADD_EDITOR_PROJECT_SUCCESS";
export const ADD_EDITOR_PROJECT_ERROR = "ADD_EDITOR_PROJECT_ERROR";
export const GET_LIST_EDITOR_PROJECT_REQUEST = "GET_LIST_EDITOR_PROJECT_REQUEST";
export const GET_LIST_EDITOR_PROJECT_SUCCESS = "GET_LIST_EDITOR_PROJECT_SUCCESS";
export const GET_LIST_EDITOR_PROJECT_ERROR = "GET_LIST_EDITOR_PROJECT_ERROR";
export const GET_EDITOR_PROJECT_REQUEST = "GET_EDITOR_PROJECT_REQUEST";
export const GET_EDITOR_PROJECT_SUCCESS = "GET_EDITOR_PROJECT_SUCCESS";
export const GET_EDITOR_PROJECT_ERROR = "GET_EDITOR_PROJECT_ERROR";
export const PUT_EDITOR_PROJECT_REQUEST = "PUT_EDITOR_PROJECT_REQUEST";
export const PUT_EDITOR_PROJECT_SUCCESS = "PUT_EDITOR_PROJECT_SUCCESS";
export const PUT_EDITOR_PROJECT_ERROR = "PUT_EDITOR_PROJECT_ERROR";
export const RESET_EDITOR_PROJECT_ERROR = "RESET_EDITOR_PROJECT_ERROR";
export const SORT_EDITOR_PROJECT_BY_KEY = "SORT_EDITOR_PROJECT_BY_KEY";

/* TargetManager */
export const INIT_TARGET_RATING = "INIT_TARGET_RATING"
export const GET_TARGET_LIST_REQUEST = "GET_TARGET_LIST_REQUEST"
export const GET_TARGET_LIST_SUCCESS = "GET_TARGET_LIST_SUCCESS"
export const GET_TARGET_LIST_ERROR = "GET_TARGET_LIST_ERROR"
export const UPDATE_TARGET_LIST_CURRENT_PAGE = "UPDATE_TARGET_LIST_CURRENT_PAGE"
export const INIT_TARGET_LIST = "INIT_TARGET_LIST"
export const GET_TARGET_ITEM_REQUEST = "GET_TARGET_ITEM_REQUEST"
export const GET_TARGET_ITEM_SUCCESS = "GET_TARGET_ITEM_SUCCESS"
export const GET_TARGET_ITEM_ERROR = "GET_TARGET_ITEM_ERROR"
export const INIT_TARGET_ITEM_META = "INIT_TARGET_ITEM_META"
export const GET_TARGET_ITEM_META_REQUEST = "GET_TARGET_ITEM_META_REQUEST"
export const GET_TARGET_ITEM_META_SUCCESS = "GET_TARGET_ITEM_META_SUCCESS"
export const GET_TARGET_ITEM_META_ERROR = "GET_TARGET_ITEM_META_ERROR"
export const UPDATE_TARGET_ITEM_META_TITLE_REQUEST = "UPDATE_TARGET_ITEM_META_TITLE_REQUEST"
export const UPDATE_TARGET_ITEM_META_TITLE_SUCCESS = "UPDATE_TARGET_ITEM_META_TITLE_SUCCESS"
export const UPDATE_TARGET_ITEM_META_TITLE_ERROR = "UPDATE_TARGET_ITEM_META_TITLE_ERROR"
export const INIT_TARGET_ITEM_ORIGIN_IMAGE = "INIT_TARGET_ITEM_ORIGIN_IMAGE"
export const GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST = "GET_TARGET_ITEM_ORIGIN_IMAGE_REQUEST"
export const GET_TARGET_ITEM_ORIGIN_IMAGE_SUCCESS = "GET_TARGET_ITEM_ORIGIN_IMAGE_SUCCESS"
export const GET_TARGET_ITEM_ORIGIN_IMAGE_ERROR = "GET_TARGET_ITEM_ORIGIN_IMAGE_ERROR"
export const INIT_TARGET_ITEM_CV_IMAGE = "INIT_TARGET_ITEM_CV_IMAGE"
export const GET_TARGET_ITEM_CV_IMAGE_REQUEST = "GET_TARGET_ITEM_CV_IMAGE_REQUEST"
export const GET_TARGET_ITEM_CV_IMAGE_SUCCESS = "GET_TARGET_ITEM_CV_IMAGE_SUCCESS"
export const GET_TARGET_ITEM_CV_IMAGE_ERROR = "GET_TARGET_ITEM_CV_IMAGE_ERROR"
export const UPDATE_TARGET_ITEM_TITLE_REQUEST = "UPDATE_TARGET_ITEM_TITLE_REQUEST"
export const UPDATE_TARGET_ITEM_TITLE_SUCCESS = "UPDATE_TARGET_ITEM_TITLE_SUCCESS"
export const UPDATE_TARGET_ITEM_TITLE_ERROR = "UPDATE_TARGET_ITEM_TITLE_ERROR"
export const DELETE_TARGET_ITEM_REQUEST = "DELETE_TARGET_ITEM_REQUEST"
export const DELETE_TARGET_ITEM_SUCCESS = "DELETE_TARGET_ITEM_SUCCESS"
export const DELETE_TARGET_ITEM_ERROR = "DELETE_TARGET_ITEM_ERROR"
export const DELETE_TARGET_ITEM_V2_REQUEST = "DELETE_TARGET_ITEM_V2_REQUEST"
export const DELETE_TARGET_ITEM_V2_SUCCESS = "DELETE_TARGET_ITEM_V2_SUCCESS"
export const DELETE_TARGET_ITEM_V2_ERROR = "DELETE_TARGET_ITEM_V2_ERROR"
export const SET_TARGET_PREDATA = "SET_TARGET_PREDATA"
export const GET_TARGET_RATING_REQUEST = "GET_TARGET_RATING_REQUEST"
export const GET_TARGET_RATING_SUCCESS = "GET_TARGET_RATING_SUCCESS"
export const GET_TARGET_RATING_ERROR = "GET_TARGET_RATING_ERROR"
export const DELETE_TARGET_RATING_ITEM = "DELETE_TARGET_RATING_ITEM"
export const UPDATE_TARGET_RATING_PAGE = "UPDATE_TARGET_RATING_PAGE"
export const UPLOAD_TARGET_RATING_REQUEST = "UPLOAD_TARGET_RATING_REQUEST"
export const UPLOAD_TARGET_RATING_SUCCESS = "UPLOAD_TARGET_RATING_SUCCESS"
export const UPLOAD_TARGET_RATING_ERROR = "UPLOAD_TARGET_RATING_ERROR"

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./project/actions";
export * from "./editorProject/actions";
export * from "./targetItemMeta/actions"
export * from "./targetItemImage/actions"
