import "./assets/vendor/bootstrap.min.css";
import "./assets/vendor/bootstrap.rtl.only.min.css";
import "react-table/react-table.css";
import 'es6-promise/auto';
import 'babel-polyfill'

let render = () => {
  import('./assets/sass/style.scss').then(x => {
    require('./AppRenderer');
  });
};
render();
