import {editorProjects} from "./index";

export const getListEditorProject = () => {
  return editorProjects.get()
}

export const createEditorProject = payload => {
  return editorProjects.post('', payload)
}

export const getEditorProject = projectId => {
  return editorProjects.get(projectId)
}

export const putEditorProject = payload => {
  return editorProjects.put(`/${payload.id}`, payload)
}

export const deleteEditorProject = projectId => {
  return editorProjects.delete(projectId)
}

export const getEditorCustomPayment = projectId => {
  return editorProjects.get(`/payment/${projectId}`)
}
