import {
  GET_TARGET_ITEM_REQUEST,
  GET_TARGET_ITEM_SUCCESS,
  GET_TARGET_ITEM_ERROR,
  UPDATE_TARGET_ITEM_TITLE_REQUEST,
  UPDATE_TARGET_ITEM_TITLE_SUCCESS,
  UPDATE_TARGET_ITEM_TITLE_ERROR,
  DELETE_TARGET_ITEM_REQUEST,
  DELETE_TARGET_ITEM_SUCCESS,
  DELETE_TARGET_ITEM_ERROR
} from '../actions';

export const initialState = {
  item: {},
  isLoading: false,
  error: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_ITEM_REQUEST:
      return {...state, isLoading: true, error: ''};
    case GET_TARGET_ITEM_SUCCESS:
      return {...state, ...action.result, isLoading: false, error: ''};
    case GET_TARGET_ITEM_ERROR:
      return {...state, isLoading: false, error: action.error}
    case UPDATE_TARGET_ITEM_TITLE_REQUEST:
      return {...state, isLoading: true, error: ''};
    case UPDATE_TARGET_ITEM_TITLE_SUCCESS:
      state.item.title = action.result.title
      return {...state, isLoading: false, error: ''};
    case UPDATE_TARGET_ITEM_TITLE_ERROR:
      return {...state, isLoading: false, error: action.error}
    case DELETE_TARGET_ITEM_REQUEST:
      return {...state, isLoading: true, error: ''};
    case DELETE_TARGET_ITEM_SUCCESS:
      return {item: {}, isLoading: false, error: ''};
    case DELETE_TARGET_ITEM_ERROR:
      return {...state, isLoading: false, error: action.error}
    default:
      return {...state};
  }
}