import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import projectSagas from './project/saga';
import editorProjectSagas from './editorProject/saga';
import targetItemMetaSaga from './targetItemMeta/saga'
// import targetListSaga from './targetList/saga';
// import targetItemSaga from './targetItem/saga';
// import targetRatingSaga from './targetRating/saga';
import targetListSaga from './userTargetList/saga';
import targetItemSaga from './userTargetItem/saga';
import targetRatingSaga from './userTargetRating/saga';
import targetItemImageSaga from "./targetItemImage/saga";


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    projectSagas(),
    editorProjectSagas(),
    targetListSaga(),
    targetItemSaga(),
    targetRatingSaga(),
    targetItemMetaSaga(),
    targetItemImageSaga()
  ]);
}
