/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";
export const subHiddenBreakpoint = 768;
export const menuHiddenBreakpoint = 768;

export const HomepagePath = `/`;
export const TargetManagetPath = '/api-tm/target-manager/projects'

export const defaultDirection = "ltr";

export const MaxLetterPassword  = 99;
export const MinLetterPassword  = 8;

export const PasswordRegularExpression = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\-_=+]).{8,99}$/;

export const fileUploadMaxLength = 50
export const fileUploadAcceptType = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/tiff",
  "image/webp",
  "image/heic",
]
export const fileUploadPreviewType = ['tif', 'heic']
export const externalLink = [
  {
    name: 'tutorial',
    ko: 'https://tough-bank-63d.notion.site/Letsee-Creator-039febca294943bbb81e34c38a52ccb1',
    en: 'https://tough-bank-63d.notion.site/Eng-Letsee-Creator-Tutorial-f824ace4ba774803b9c08b50bfd3bff5'
  },
  {
    name: 'price',
    ko: 'https://www.letsee.io/ko/pricing/',
    en: 'https://www.letsee.io/pricing/'
  },
  {
    name: 'faq',
    ko: 'https://www.letsee.io/ko/pricing/#FAQ',
    en: 'https://www.letsee.io/pricing/#FAQ'
  }
]

export const cautionViewRatio = 90

// export const isBackEndLocal = () => {
//   const originPath = window.location.origin;
//   if (originPath.indexOf('localhost') > -1 || originPath.indexOf('0.0.0.0') > -1 || originPath.indexOf('127.0.0.1') > -1) {
//     return true
//   } else {
//     return false
//   }
// }
