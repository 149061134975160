import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
  SET_TARGET_PREDATA,
  GET_TARGET_RATING_REQUEST,
  GET_TARGET_RATING_SUCCESS,
  GET_TARGET_RATING_ERROR,
  UPLOAD_TARGET_RATING_REQUEST,
  UPLOAD_TARGET_RATING_SUCCESS,
  UPLOAD_TARGET_RATING_ERROR
} from '../actions';
import {createFormData} from "../../helpers/Utils";
import {getUserTargetRating, uploadUserTargetRating} from "../../api/userTargetManager";

export function* watchGetTargetRating() {
  yield takeEvery(GET_TARGET_RATING_REQUEST, getTargetRatingSaga);
}

const getTargetRatingAsync = async (payload) => {
  const {ratingFiles, projectId} = payload
  const formData = createFormData('target', ratingFiles)
  const { data } = await getUserTargetRating({projectId, formData})
  return data
}

function* getTargetRatingSaga({payload}) {
  try {
    yield put({
      type: SET_TARGET_PREDATA,
      result: {
        ratingList:payload.previewData,
        ratingFiles: payload.ratingFiles
      }
    })
    const result = yield call(getTargetRatingAsync, payload)
    yield put({
      type: GET_TARGET_RATING_SUCCESS,
      result: result
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: GET_TARGET_RATING_ERROR,
      error: error.response.data
    })
  }
}

export function* watchUploadTargetRating() {
  yield takeEvery(UPLOAD_TARGET_RATING_REQUEST, uploadTargetRatingSaga);
}

const uploadTargetRatingAsync = async (payload) => {
  const {rating, ratingFiles, projectId, width, changedFilename} = payload
  let formData;
  if(ratingFiles.length === 1 && changedFilename.length > 0) {
    formData = createFormData('target', ratingFiles, changedFilename)
  } else {
    formData = createFormData('target', ratingFiles)
  }
  const result = await uploadUserTargetRating({projectId, formData, rating, width})
  return result
}

function* uploadTargetRatingSaga({payload}) {
  try {
    const result = yield call(uploadTargetRatingAsync, payload)
    yield put({
      type: UPLOAD_TARGET_RATING_SUCCESS,
      result: result
    })
    yield put({
      type: UPLOAD_TARGET_RATING_SUCCESS,
      result: result
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: UPLOAD_TARGET_RATING_ERROR,
      error: error.response.data
    })
  }
}

export default function* targetRatingSaga() {
  yield all([
    fork(watchGetTargetRating),
    fork(watchUploadTargetRating)
  ])
}