import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_TARGET_ITEM_REQUEST,
  GET_TARGET_ITEM_SUCCESS,
  GET_TARGET_ITEM_ERROR,
  UPDATE_TARGET_ITEM_TITLE_REQUEST,
  UPDATE_TARGET_ITEM_TITLE_SUCCESS,
  UPDATE_TARGET_ITEM_TITLE_ERROR,
  DELETE_TARGET_ITEM_REQUEST,
  DELETE_TARGET_ITEM_SUCCESS,
  DELETE_TARGET_ITEM_ERROR, GET_TARGET_LIST_REQUEST, UPDATE_TARGET_LIST_CURRENT_PAGE
} from '../actions';
import {TargetManagetPath} from "../../constants/defaultValues";
import {deleteUserTargetItem, updateUserTargetItemTitle} from "../../api/userTargetManager";

function* watchGetTargetItem() {
  yield takeEvery(GET_TARGET_ITEM_REQUEST, getTargetItemSaga);
}

const getTargetItemAsync = async (payload) => {
  const {item} = payload
  if(!item['image_with_keypoint_download_url'].includes(String(TargetManagetPath))) {
    item.featureimage = null
    return item
  }
  const token = JSON.parse(sessionStorage.getItem("userinfo")).token;
  const url = item['image_with_keypoint_download_url']
  if(url) {
    const imageResult = await axios.get(url, { headers: { 'Authorization': token }, responseType: 'arraybuffer' }, )
    const image = btoa(
      new Uint8Array(imageResult.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    item.featureimage = `data:${imageResult.headers['content-type'].toLowerCase()};base64,${image}`;
  }else {
    item.featureimage = null
  }
  return item
}

function* getTargetItemSaga({payload}) {
  try {
    const result = yield call(getTargetItemAsync, payload)
    yield put({
      type: GET_TARGET_ITEM_SUCCESS,
      result: {item: result}
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: GET_TARGET_ITEM_ERROR,
      error: error.response.data
    })
  }
}

function* watchUpdateTargetItemTitle() {
  yield takeEvery(UPDATE_TARGET_ITEM_TITLE_REQUEST, updateTargetItemTitleSaga)
}

const updateTargetItemTitleAsync = async (payload) => {
  const result = await updateUserTargetItemTitle(payload)
  if(result) return payload.title
}

function* updateTargetItemTitleSaga({payload}) {
  try {
    const result = yield call(updateTargetItemTitleAsync, payload)
    yield put({
      type: UPDATE_TARGET_ITEM_TITLE_SUCCESS,
      result: {title: result}
    })
  } catch(error) {
    yield put({
      type: UPDATE_TARGET_ITEM_TITLE_ERROR,
      error: error.response.data
    })
  }
}

function* watchDeleteTargetItem() {
  yield takeEvery(DELETE_TARGET_ITEM_REQUEST, deleteTargetItemSaga)
}

const deleteTargetItemAsync = async (payload) => {
  console.log(payload)
  const result = await deleteUserTargetItem(payload)
  return result
}

function* deleteTargetItemSaga({payload}) {
  try {
    yield call(deleteTargetItemAsync, payload)
    yield put({
      type: DELETE_TARGET_ITEM_SUCCESS
    })
    const {targetListData, page, pageSize, history} = payload
    let newPage = page
    if(targetListData.length%pageSize === 1) {
      newPage = page - 1
      yield put({
        type: UPDATE_TARGET_LIST_CURRENT_PAGE,
        payload: {
          currentPage: newPage
        }
      })
    }
    yield put({
      type: GET_TARGET_LIST_REQUEST,
      payload: {
        page: newPage,
        pageSize: 8
      }
    })

    history.push('/targetManager')
  } catch(error) {
    yield put({
      type: DELETE_TARGET_ITEM_ERROR,
      error: error.response.data
    })
  }
}

export default function* targetItemSaga() {
  yield all([
    fork(watchGetTargetItem),
    fork(watchUpdateTargetItemTitle),
    fork(watchDeleteTargetItem)
  ])
}
