/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  "general.copyright": "LetSee © 2019 All Rights Reserved.",
  "general.letsee": "Letsee, inc.",
  "general.email": "Email",
  "general.won": "$",

  "general.message": "Message",
  "general.success": "Success",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Login",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "Email address",
  "user.email_hangul": "Email",
  "user.password": "Password",
  "user.password_re": "Confirm password",
  "user.forgot-password-question": "Forgot password",
  "user.fullname": "Full Name",
  "user.login-button": "Login",
  "user.register-button": "Sign up",
  "user.reset-password-button": "Reset password",
  "user.forgot-password-button": "Request mail",
  "user.buy": "BUY",
  "user.username": "Name",
  "user.service_policy": "Terms of service",
  "user.info_policy": "Privacy policy ",
  "user.agree": "Agree",
  "user.agree_terms_of_service_and_privacy_policy": '<a class="letsee_link letsee_link_underline" target="_blank" href="/user/service">Terms of service</a>, <a class="letsee_link letsee_link_underline" target="_blank" href="/user/privacy">Privacy Policy</a> and <a class="letsee_link letsee_link_underline" target="_blank" href="/user/beta-service-policy">Beta Service Policy</a> agreement',
  "user.cancel": "Cancel",
  "user.company": "Address (Company, School, Organization name)",
  "user.shortcompany": "Organization",
  "user.policy_service": "Terms of service",
  "user.policy_privacy": "Privacy policy",
  "user.phone": "Phone number",
  "user.passwordchange": "Reset password",
  "user.placeholder_company": "Organization information sample",
  "user.placeholder_password": "Current password",
  "user.placeholder_newpassword": "New password",
  "user.placeholder_confirmpassword": "Confirm new password",
  "forgotyourpassword.needemail": "Enter your email address to reset your password",
  "logout.confirm": "Do you want to log out?",
  "logout.successfully": "You've been logged out successfully",
  "user.save_email": "Save email",
  "user.examplename": "Enter your name.",
  "user.companyinput": "Enter company, school and organization names.",
  "user.savednewpassword": "Password successfully changed.",

  /* 03.Menu */
  "menu.app": "Home",
  "menu.dashboards": "Dashboards",
  "menu.gogo": "Gogo",
  "menu.start": "Start",
  "menu.second-menu": "Second Menu",
  "menu.second": "Second",
  "menu.ui": "UI",
  "menu.charts": "Charts",
  "menu.chat": "Chat",
  "menu.survey": "Survey",
  "menu.todo": "Todo",
  "menu.search": "Search",
  "menu.docs": "Docs",
  "menu.blank-page": "Blank Page",
  "menu.dashboard": "Dashboards",
  "menu.project": "Workspace",
  "menu.workspace": "Workspace",
  "menu.project_add": "Create project",
  "menu.target_manager": "Target manager",
  "menu.fbxToGlb": "FBX to GLB",
  "menu.target_image": "Target Image",
  "menu.license": "License plan",
  "menu.payment_history": "Payment history",
  "menu.setting": "Setting",
  "menu.tutorial": "Tutorial",
  "sub.license": "- License/ Payment",
  "sub.bill": "- Payment history",
  "sub.setting_member": "- Member",
  "sub.setting_key": "- Key",
  "menu.myinfo": "My information",
  "menu.logout": "Log out",

  /* 04.Error Page */
  "pages.error-title": "An error was found. This page cannot be accessed.",
  "pages.error-code": "Error code",
  "pages.go-back-home": "Go to home",
  "errors.empty_email": "Please enter your email address.",
  "errors.invalid_email": "Please enter in email format.",
  "errors.invalid_email_register": "ID is in email format.",
  "errors.empty_password": "Please enter the password.",
  "errors.empty_confirm_password": "Please enter the confirm password.",
  "errors.short_password": "Password must be at least 8 letters containing alphabets, numbers and special characters.",
  "errors.rule_password": "Password must include at least one letter of alphabets, numbers and special characters each.",
  "errors.password_again": "Please check your new password",
  "errors.confirm_password": "Passwords do not match.",
  "errors.empty_projectname": "Please enter the project name.",
  "errors.empty_projectdomain": "Please enter the project domain",
  "errors.notregistered": "This email is not registered.",
  "errors.loginfail": "Log in failed.",
  "errors.exist_email": "This email has already existed.",
  "errors.register_fail": "Sign up failed.",
  "errors.notuser": "There is no user registered with this email.",
  "errors.sameuser": "The role of owner cannot set up members.",
  "errors.overinvitemember": "This member was invited. ",
  "errors.ownerrolechangefailr": "The role of owner cannot be changed.",
  "errors.ownercannotdeleted": "The role of owner cannot be deleted.",
  "errors.userisdroped": "This user has been withdrawn.",
  "errors.passwordreset": "Your password has been reset.",
  "errors.passwordresetvalidate1": "The password validation method has changed.",
  "errors.passwordresetvalidate2": "Please change your password with the new validation method!",
  "errors.notargreepollicy": "Check Service & Payment Policy and/or monthly payment box",
  "errors.notargreepollicy2": "Check Service & Payment Policy and/or monthly payment box",
  "errors.projectnamevalid": "Please enter a project name of 4 or more and less than 20 characters.",
  "errors.projectnameonlyenglish": "Only alphabets and numbers can be entered",
  "errors.empty_projectnotvalid": "It's not a valid domain.",
  "errors.needauthorize": "This page requires login.",
  "errors.phonenumbertoolong": "The phone number format is different.",
  "errors.phonenumberinvalid": "The phone number is in the wrong format",

  "notice.copied": "Copied",
  "notice.copy.short": "Copy",
  "notice.copied.short": "Copied",

  "authkey.howtouse": "How to use authentication key",
  "auth_key.name": "Authentication key",
  "auth_key.copy_button": "Copy Authentication key",
  "auth_key.copy_complete": "The authentication key has been copied",

  "members.titlewaiting": "Waiting",
  "member.waiting": "Standby",

  "project.title": "Please refer to the information below.",
  "project.help_message0": "When created a project, one authentication key is issued and you can develop AR content after applying the source. ",
  "project.help_link0": "Go to Tutorial",
  "project.help_message1": "The number of AR marker recognition is limited, but you can increase the number of times allowed when upgrading to a paid plan. ",
  "project.help_link1": "Go to Product Information",
  "project.help_message2": "The purchased paid plan belongs to the project to which the paid plan is applied.",
  "project.help_message3": "Regular payment of paid plans will be canceled when the project is deleted and will not be charged from next month.",
  "project.help_message4": "However, the current month fee is non-refundable, so please decide carefully when deleting. ",
  "project.help_link2": "Go to User Guide",
  "project.label_add": "Create a new project ",
  "project.invite": "Invited team members.",
  "project.nameforadding": "Project name",
  "project.domainforadding": "Site domain",
  "project.projectname_title": "Project",
  "project.project_add_error": "Project Add Error",
  "project.project_count_more_than_3": "You cannot create more than 3 projects.",

  "member.admin": "Manager",
  "member.member": "Team member",
  "member.pending": "Pending",

  "forms.modifytitle": "Manage my information",
  "forms.modifysuccess": "User information has been modified.",
  "forms.passwordinvalid": "Passwords do not match.",
  "forms.error": "An error has occurred. Please try again.",
  "forms.cancel": "Cancel",
  "forms.confirm": "Confirm",
  "forms.modify": "Save",
  "forms.change": "Modify",
  "forms.delete": "Delete",
  "forms.close": "Close",
  "forms.unsubscribe": "Confirm",
  "forms.save": "Save",
  "forms.modify2": "Change",
  "forms.next": "Continue",
  "forms.checklicensepolicy": "I acknowledge that I’ve read and  <linkItem>Service & payment policy</linkItem>",
  "forms.checklicenseregular": "I acknowledge and agree to monthly payment",
  "form.emptyphonenumber": "Enter your phone number",
  "form.errorphonenumber": "Country code / Telephone number you entered is not valid",

  "dashboard.only1image": "When you change domain, a failure may occur in the existing AR content.",
  "dashboard.domainnamechange": "Change domain name",
  "dashboard.notvaliddomain": "It is not a valid domain.",
  "dashboard.servicedomain": "Service domain",
  "dashboard.projectpv": "Project PV",
  "dashboard.freepv": " Free PV provided",
  "dashboard.detailpv": "Project PV detail",
  "dashboard.downloadexcel": "Excel download",
  "dashboard.percpv": "Per CPV on excess ",
  "dashboard.upgrade": "Upgrade",
  "dashboard.domain_fail": "The change was unsuccessful because either there is a problem with the domain, or there is a problem with the server. Please try again in a few minutes.",
  "dashboard.project_create": "Created on",
  "dashboard.pv_date": "Date",
  "dashboard.pv_pv": "PV",

  "auth_key.titlecopy": "Copy",
  "auth_key.youneedtochangekey": "Change your_app_key in the code block below to the copied actual app key.",
  "license.payment_title": "Apply for license plan",
  "license.usage_fee": "License usage fee",
  "license.basic_fee": "License fee",
  "license.total_fee": "Total usage fee",
  "license.month_flat": "Regular fixed amount",
  "license.base_pv_exceed_fee": "Basic PV excess charge (Excess CPV 000)",
  "license.discount_fee": "Discount",
  "license.vat_fee": "VAT",
  "license.recent_year_fee": "Payment details over the last year",
  "license.fee_division": "Fee division",
  "license.billing_date": "Payment date",
  "license.product_name": "License classification",
  "license.base_fee": "License fee",
  "license.addtional_fee": "Additional fee",
  "license.just_fee": "Total payment amount",
  "license.only1image": "License in use",
  "license.upgradelincense": "License upgrade",
  "license.plzselectlicense": "Choose a plan based on your expected AR PV.",
  "license.pvislimited": "PV has reached the limit. Please upgrade.",
  "license.youneedupgrade": "If the default PV is exceeded, upgrade the license.",
  "license.regularpayment": "Subscription",
  "license.whyoff": "When set to unsubscribe, charges are only charged for the current month, and are not charged from the following month.",
  "license.subscribe_benefit": "Apply for monthly plan and enjoy the service without watermark",
  "license.plan": "License plan",
  "license.youcantrefund": "The current monthly fee is not refundable.",
  "license.licenseupgrade": "License upgrade",
  "license.youpayvat": "10,000 won (including VAT) is charged by default.",
  "license.pvovermoremondy": "If the default amount of Page View is exceeded, an excess fee will be charged.",
  "license.license": "License",
  "license.summary": "Order summary",
  "license.whopay": "Orderer information",
  "license.needname": "Please enter your name",
  "license.needphone": "Please enter your phone number",
  "license.needcompany": "Enter the name of your school or organization.",
  "license.needemail": "Please enter your email",
  "license.policy": "Confirm the terms and conditions",
  "license.claimmoney": "Billing fee",
  "license.monthclaimmoney": "Regular billing fee",
  "license.moneyunit": "won",
  "license.usage_productname": "Products used",
  "license.howtopay": "Payment method",
  "license.desc": "Notice - The WebAR SDK provided by Letsee Co., Ltd. can be used without distinction of iPhone or Android phones as it operates in a web environment, and from the perspective of AR content creators, AR contents can be produced smoothly by web developers and web designers even if there is no AR technology personnel.",
  "license.address": "Letsee Co., Ltd. 15th floor, Teheran-ro 82-gil, Gangnam-gu, Seoul",
  "license.boss": "CEO of Letsee Co., Ltd.",
  "license.titlefree": "Free",
  "license.cantsell": "Prohibited for commercial use",
  "license.freeisnotlong": "When exceeding the limit, it is necessary to switch to a paid plan",
  "license.bigproject": "Large Project",
  "license.nego": "Price negotiation",
  "license.finalprice": "Total payment amount",
  "license.paymethod": "Payment method",
  "license.titlebase": "Basic",
  "license.everymonth": "Monthly ",
  "license.pv_per_month": "{{viewcount}} PV per month",
  "license.agreepolicyplz": "Please agree to the terms of use and regular payment.",
  "license.formisempty": "There is an empty item.",
  "license.paymentofftitle": "Cancel periodic payments",
  "license.paymentoffdesc": "If you switch your mind to continue your monthly plan,<br> press Subscribe button by <bold>{{itemDate}}</bold>",
  "license.dismisspayment": "Monthly plan cancelled",
  "license.dismisspayment_description": "After <bold>{{itemDate}}</bold>,<br> you'll be switched back to 'free' plan and your payment information will be deleted",
  "license.cancelPayment": "Would you like to cancel the monthly plan?",
  "license.trouble": "A problem has occurred. Please try again in a few minutes.",
  "license.licensechanged": "You have subscribed to the monthly plan",
  "license.cannotupgrade": "There was a problem loading the current PV values.\nPlease try again later. ",
  "license.youcantchangelicense": "The license cannot be changed for this project.",
  "license.youwillchangelicense1": "The license is scheduled to be changed to",
  "license.youwillchangelicense2": "\nso it cannot be changed ",
  "license.youcantchangesamelicense": "It cannot be changed to the same license.",
  "license.plzlatertry": "Something went wrong.\nPlease try again later",
  "license.pvisfullyouneedupgrade": "PV has reached its limit. Upgrade is required.",
  "license.pvisfullyouneedupgrade2": "-PV has reached its limit. Upgrade is required.",
  "license.pvisfullnotice": "[Attention] the service will be suspended if the given PV is used up",
  "license.pvwillbeupgrade_title": "Upgrade",
  "license.pvwillbeupgrade_description": "Unused PV left in your <bold>{{itemCurrentGrade}} plan</bold> will be added on to the <boldNext>{{itemNextGrade}} plan</boldNext>",
  "license.pvwillbeupgrade_description_forfree": "Would you like to upgrade to <bold>{{itemGrade}} plan?</bold>",
  "license.warningdowngrade_title": "Downgrade",
  "license.warningdowngrade_description": "The current plan  <bold>{{itemCurrentGrade}}</bold> will be maintained  <boldDate>{{itemDate}}</boldDate>, and will be downgraded after the expiry date",
  "license.contactus_title": "Enterprise inquiry",
  "license.contactus_textarea": "Project scope and purpose of use",
  "license.contactus_textarea_placeholder": "please enter the information",
  "license.contactus_sent_title": "Successfully sent",
  "license.contactus_sent_description": "Your inquiry has been sent",
  // "license.add_fee_viewcount": "전월 초과 CPV(+",
  // "license.cannotaccess": "접근할 수 없는 메뉴입니다.",
  "license.wouldyouchangeplan": "Would you like to change your plan?",
  "license.warningdowngrade1": "The plan you are currently using will remain until ",
  "license.warningdowngrade2": "and will be downgraded to the",
  "license.warningdowngrade3": "changed plan after expiration.",
  "license.add_fee_viewcount": "the previous month exceeding CPV(+",
  "license.cannotaccess": "This menu is not accessible.",
  "license.thisitemisdeleted": "This is a payment product that cannot be accessed.",
  "license.": "This is a payment product that cannot be accessed.",
  "license.licenseyouselcted": "Selected license content",
  "license.detail_label": "Details",
  "license.payment_success": "Payment has been successfully completed.",
  "license.payment_fail": "Payment has failed.",
  "license.payment_failcode": "Failure code : ",
  "license.payment_failmsg": "Please contact your credit card company.",
  "license.changeiscompletedTitle": "Change saved",
  "license.changeiscompleted": "Your plan has been changed to the <bold>{{itemGrade}} plan</bold> . ",
  "license.checkyourservice": "Please check if there is any effect on existing services ",
  "license.current_use": "Currently in use",
  "license.buy": "Purchase now",
  "license.free_conversion": "Switch back to free plan",
  "license.contact": "Contact",
  "license.month": "Month",
  "license.total_fee2": "The total fee",
  "license.basic_fee2": "License fee",
  "license.watermark": "Letsee Watermark ",
  "license.include": "Included",
  "license.notinclude": "removed",
  "license.everymonth2": "Monthly ",
  "license.arpageview": "AR Page View provided",
  "license.ar_pv_per_month": "Up to {{viewCount}} view / month",
  "license.planselect": " Choose the plan",
  "license.update": " update",
  "license.notfree": "Paid",
  "license.enterprise": "When providing 100,000 AR Page View or more per month",
  "license.contactus": "Separate inquiry",
  "license.detail_page": "Details",
  "license.detail_link": "View content",
  "license.detail_link.paypal": "View payment details in your PayPal account",
  "license.enterprise_arview": "Provide customized AR Page View",
  "license.separate_inquiry": "Contact",
  "license.payer": "Payer",
  "license.phonenumber": "Phone number",
  "license.project_last_payment_date": "Last payment date",
  "license.project_next_payment_date": "Project Next Payment Date",
  "license.confirm_redirect_to_paypal": "To continue to make recurring billing, please choose the appropriate payment method below!",
  "license.no_paypal_plan_id": "Paypal is temporarily  unavailable,\nPlease contact the administrator for more detail.",
  "license.perview_avaliable": "Available",
  "license.link_license": "Details",
  //'license.link_license': 'View plan details',
  "license.grade_free_type": "free trial",
  "license.grade_starter_type": "Small project",
  "license.grade_advance_type": "Small/Medium project",
  "license.grade_professional_type": "Medium/Big project",
  "license.grade_enterprise_type": "Large Project",
  "license.support_session": "Session provided",
  "license.enterprise_benefit": "Enterprise license <br> for customized session",
  "license.support_pv_alert": "Send alert e-mail if the remaining PV is less than 10%",
  "license.support_report": "Provide advanced analysis reports",
  "license.support_email": "Technical support via email",

  "payment_history.title": "License billing statement",
  "payment_history.th_date": "Payment Date",
  "payment_history.th_plan": "License plan",
  "payment_history.th_fee": "License fees(including VAT)",
  "payment_history.th_payer": "Name",
  "payment_history.th_page": "No payment details",

  "setting.title_project": "Manage project",
  "setting.change_title_project": "Change project name",
  "setting.change_title_project_placeholder": "Enter new project name",
  "setting.changed_title": "Project name has been changed.",
  "setting.change_domain": "Edit domain",
  "setting.changed_domain_caution_title": "Be careful with editing your domain!",
  "setting.changed_domain_caution_description": "Existing AR contents may not work properly after changing the domain",
  "setting.changed_domain": "Domain address has been changed.",
  "setting.titleprojectdelete": "Delete Project",
  "setting.delete_notice_title": "You can't restore a project once deleted",
  "setting.deletedesc0": "When you delete a project, the license subscription payment will be automatically cancelled.",
  "setting.deletedesc1": "Existing services will be discontinued, so please decide carefully.",
  "setting.deletedesc2": "Project once deleted cannot be restored.",
  "setting.askdelete": "Are you sure you want to delete it?",
  "setting.askdelete_placeholder": "Please enter the DELETE character.",
  "setting.askdelete_error": "Enter DELETE to delete the project",
  "setting.deleted_project": "Project is deleted.",
  "setting.title_appkey": "Authentication key management",
  "setting.manage_appkey_notice": "Attention!) All of the contents inside the project will cease to work when set to off.",
  "setting.manage_appkey_stop_title": "Authentication key off",
  "setting.manage_appkey_stop_description": "The project service will be suspended.",
  "setting.manage_appkey_start_title": "Authentication key on",
  "setting.manage_appkey_start_description": "The project service is enabled.",
  "setting.member_invite_title": "You can invite Team members by entering email address",
  "setting.member_invite_role": "Assign roles",
  "setting.invitesuccess": "You have been invited to the Project.",
  "setting.success": "Changed sucessfully",
  "setting.deletesuccess": "Deleted",
  "setting.statuson": "Authentication key was turned ON.",
  "setting.statusoff": "Authentication key was turned OFF.",
  "setting.realydelete": "Are you sure you want to delete it? Existing services will be discontinued. Please enter the DELETE character.",
  "setting.changecomplete": "Changed sucessfully",
  "setting.keyoffmessage": "When the authentication key is turned off, all contents related to the project are stopped.",
  "setting.titlechange": "Change",
  "setting.titlesave": "Save",
  "setting.titlecancel": "Cancel",
  "setting.keyonoff": "Authentication key ON / OFF",
  "setting.titledoit": " ",
  "setting.titleprojectnamechange": "Change project name",
  "setting.reallydelete": "Are you sure you want to delete?",
  "setting.addnewmember": "Add a new member",
  "setting.projectmembersetting": "Project member setting",
  "setting.project_auth": "Project permissions",
  "setting.member": "Team member",
  "setting.admin": "Manager",
  "setting.accessdashboard": "Access to the Dashboard, Authentication key, AR marker test menu",
  "setting.accesslicense": "Access to the License/ Payment menu",
  "setting.acesssetting": "Access to the Setting menu",
  "setting.accessauth": "Setting authority",
  "setting.addmember": "Added as a team member",
  "setting.addadmin": "Added as Manager",
  "setting.division": "Division",
  "setting.auth": "Authority",
  "setting.recentlogin": "Recently logged in",
  "setting.waitasmember": "Waiting as a team member",
  "setting.waitasadmin": "Waitting as manager",
  "setting.youcantaccess": "Team members are restricted from accessing project creation, license/ payment menu and setting menu.",
  "setting.titledelete": "Delete",
  "setting.titlesetting": "Setting",
  "setting.invite_mail.subject": "[Letsee] You have been invited to Letsee project.",
  "setting.invite_mail.desc1": "Letsee for Developers project's",
  "setting.invite_mail.desc2": "You have been invited to become manager or team member.",
  "setting.invite_mail.desc3": "Click the button below to complete the invitation",
  "setting.invite_mail.desc4": "You will be taken to the Letsee for Developers service.",
  "setting.invite_mail.acccept_invitation": "Accept invitation",

  "resetpassword.changecomplete": "Your password has been changed.",
  "resetpassword.login": "Please log in again.",
  "resetpassword.mail.subject": "[Letsee] Set a password.",
  "resetpassword.mail.title_desc1": "Letsee for Developers site's ",
  "resetpassword.mail.body_desc1": "Your account has been locked.",
  "resetpassword.mail.body_desc2": "If you verify your account, it will be unlocked.",
  "resetpassword.mail.body_desc3": "Change your password to protect your account",
  "resetpassword.mail.body_verifylink": "<a href='{{link}}' >Verify your account</a>",
  "resetpassword.mail.body_verifyText": "Verify your account",
  "resetpassword.resetpassword_susscess": "Reset Password Success",

  "forgotpassword.mail.subject": "[Letsee] Please reset your password.",
  "forgotpassword.mail.logoalt": "Letsee's logo",
  "forgotpassword.mail.desc1": "Letsee for Developers site's ",
  "forgotpassword.mail.desc2": "Please reset your password.",
  "forgotpassword.mail.desc3": "Click the button below to reset your password.",
  "forgotpassword.mail.desc4": "You will be taken to the Letsee for Developers service.",
  "forgotpassword.mail.changepass": "Change Password",
  "forgotpassword.mail.redirectmail": "Email",
  "forgotpassword.mail.footer1": "This mail is only for sending, you cannot reply.",
  "forgotpassword.mail.mailto": "If you have any question, please send us via <a href='{{mail_address}}' {{inline_style}}'> email </a>",
  "forgotpassword.forgot_password_success": "Forgot Password Success",
  "forgotpassword.forgot_password_error": "Forgot Password Error",
  "forgotpassword.please_check_your_email": "Please check your email.",
  "forgotpassword.please_login_with_your_new_password": "Please login with your new password.",
  "forgotpassword.sent_email1": "Authentication email was sent to ",
  "forgotpassword.sent_email2": " email address.",

  "register.youneedpassword": "Please enter your password.",
  "register.youneedpasswordconfirmation": "Please confirm your password.",
  "register.youneedemail": "Please enter your email address.",
  "register.thankyou": "Thank you",
  "register.eamilsent": "An account verification email has been sent.",
  "register.sent_email1": "Authentication email was sent to ",
  "register.sent_email2": " email address.",
  "register.youneedauthemail": "After authentication, the subscription is completed.",
  "register.mail.subject": "[Letsee] Please verify your account",
  "register.mail.desc1": "Letsee for Developers site's ",
  "register.mail.desc2": "Registration completed successfully.",
  "register.mail.desc3": "Click the button below to verify your account.",
  "register.mail.desc4": "You will be taken to the Letsee for Developers service.",
  "register.mail.verifyaccount": "Verify your account",
  "register.mail.footer1": "This mail is only for sending, you cannot reply.",
  "register.mail.mailto": "If you have any question, please send us via <a href='{{mail_address}}' {{inline_style}}'> email </a>",
  "register.signup_error": "SignUp Error",

  "login.authmail": "Authentication email was sent to",
  "login.mailto": " email address.",
  "login.emailresent": "The email has been sent.",
  "login.authmailiswaiting": "Authentication is pending.  ",
  "login.afterauthlogin": "You can log in after account authentication.",
  "login.resent_email_plz": "Resend verification email",
  "login.authcomplete": "Account authentication has been completed.",
  "login.youneedloginin": "You can use it after logging in.",
  "login.havenotletsee": "Do you have an account?",
  "login.fotgotpassword": "Forgot password?",
  "login.mailsent": "The email has been sent.",
  "login.emailisnotexist": "The email does not exist.",
  "login.willbelocked1": "The password is incorrect, please enter password again. The account will be locked if you enter password incorrectly over 5 times.1/5",
  "login.willbelocked2": "The password is incorrect, please enter password again. The account will be locked if you enter password incorrectly over 5 times.2/5",
  "login.willbelocked3": "The password is incorrect, please enter password again. The account will be locked if you enter password incorrectly over 5 times.3/5",
  "login.willbelocked4": "The password is incorrect, please enter password again. The account will be locked if you enter password incorrectly over 5 times.4/5",
  "login.willbelocked5": "The password is incorrect, please enter password again. The account will be locked if you enter password incorrectly over 5 times.5/5",
  "login.mailcantsend": "Mail can not be sent at this time.\nPlease try again later.",
  "login.passwordfiveerror": "Your account has been locked\ndue to 5 failed login attempts.",
  "login.unlockaccount": "Unlock your account",
  "login.confirm": "Confirm",
  "login.authmail.subject": "[Letsee] Please verify your account",
  "login.authmail.body_desc1": "Thank you for signing up.",
  "login.authmail.body_desc2": "Please click the link below to verify your account.",
  "login.authmail.body_verifylink": "<a href='{{link}}'>Verify your account</a>",
  "login.authmail.body_verifyText": "Verify your account",

  "project.ruleName": "Enter 4 to 20 letters for the project name.(Special characters such as -,_ are allowed only.)",
  "project.nameValidationErrorSpace": "No spaces are allowed on the first or last line",
  "project.nameValidationErrorName": "Make sure you've entered a valid name \n(Special characters not allowed except -, _)",
  "project.nameValidationErrorNameWithSpace": "Make sure you've entered a valid name \n(Special characters not allowed except -, _)",
  "project.nameValidationErrorNameSize": "A valid name should be made of 4 to 20 characters",
  "project.nameValidationErrorEmpty": "Enter project name",
  "project.needdomain": "www.example.com",
  "project.adderror": "An error occurred and the project could not be created.",
  "project.notexistfreeplan": "There is no free plan,\nplease contact manager.",

  "modify.present_password": "Current Password",
  "modify.new_password": "New password",
  "modify.new_password_re": "Confirm new password",
  "modify.saved": "The information has been saved.",
  "modify.notice": "If you do not want to change to a new password, just enter the current password and save it.",
  "modify.error": "Modify Error",
  "modify.success": "Modify Success",

  "markettest.only1image": "Sorry. Only one image can be uploaded at a time.",
  "markettest.2mcanupload": "Only files below 2M can be uploaded.",
  "markettest.saved": "Saved",
  "markettest.imagecantrestore": "After deleting the image, it cannot be recovered.",
  "markettest.imagedragndrop": "Select or drag and drop the file to be uploaded",
  "markettest.beforeupload": "Please refer to guideline before uploading images",
  "markettest.imagepixelstory": "You can upload JPG or PNG images of 640,000 pixels 2MB or less.",
  "markettest.youcantest": "Through this test, you can check whether the image target is valid or not.",
  // 'markettest.pvisnorelated' : 'The test is independent of the PV provided as a license.',
  // 'markettest.pvisfifty' : 'PV per image is limited to 50 times.',
  "markettest.imagelist": "Image target list",
  "markettest.imagemarkettest": "Image marker test",
  "markettest.form": "Format",
  "markettest.size": "Size",
  "markettest.resolution": "Resolution",
  "markettest.savedday": "Saved day",
  "markettest.howtotest": "How to test",
  "markettest.desc1": "Scan the QR code with your smartphone",
  "markettest.desc2": "The camera is activated in the browser.",
  "markettest.desc3": "Please display the image on the left.",
  "markettest.desc4": "When the letter “TEST OK” is confirmed, it can be used as an image target.",
  "markettest.desc5": "However, even if recognition is successful",
  "markettest.desc6": "When the recognition rate drops or trembles",
  "markettest.desc7": "The image may be inappropriate as an image target.",
  "markettest.desc8": "In this case, it may be replaced with another image",
  "markettest.desc9": "It is necessary to improve the stability through image correction.",
  "markettest.desc10": "AR marker image upload",
  "markettest.desc11": "Check in advance whether the image used as the AR marker works properly.",
  "markettest.dog": " pcs",
  "markettest.imagedeletetitle": "Delete image",
  "markettest.delete": "It has been deleted.",
  "markettest.notfile": "The file format is not correct.",
  "markettest.tolargeimage": "Only images of 640,000 pixels or less can be uploaded.",
  // 'markertest.imagedesce' : 'Images are kept for 30 days from the date of registration.',
  "markettest.upload_message": "Upload Message",
  "markettest.upload_error": "Upload Error",

  "payment.additionalcpv": "Additional CPV",
  "payment.usenow": "Use now",
  "payment.changeplan": "Change plan",
  "payment.permonth": "won per month",
  "payment.infomation": "Payment information",
  "payment.paymentbyautocard": "Automatic card payment",
  "payment.paymentbyetc": "Other payment method",
  "payment.failmsg": "Payment has failed. Please try again.",
  "letsee.foundationday": "Oct 25, 2019",
  "payment.paymentinfo_title": "Plan details for selected <LicenseGrade /> plan",
  "payment.incis": "INCIS",
  "payment.paypal": "PayPal",

  "form-components.start": "Start",
  "form-components.end": "End",

  "service.beta": "Beta Service Policy",
  "service.policy": "Terms of Service",
  "service.privacy": "Privacy policy",
  "service.paymentpolicy": "Payment policy",
  "server.cannotconnect": "Could not connect with the server.\nPlease try again.",

  "footer.servicepolicy": "Terms of Service",
  "footer.privacypolicy": "Privacy policy",
  "footer.betaServicePolicy": "Beta Service Policy",

  "footer.representative": "Representative: Ahn SangChul",
  "footer.representative_number": "Representative number: {{number}}",
  "footer.business_registration_number": "Business registration number: {{number}}",
  "footer.business_address": "Address: 8th floor, 15 Teheran-ro 82-gil, Gangnam-gu, Seoul",

  "placeholder.phonenumber": "Enter your phone number",

  "target.fileNameDefaultValidate": "File name should not exceed over 30 characters (Special characters not allowed except -, _)",
  "target.fileNameValidateErrorEmptyName": "enter file name",
  "target.fileNameValidateErrorOverLength": "You have a file name longer than 30 characters",
  "target.fileNameValidateErrorName": "Please enter a valid name (Special characters not allowed except -, _)",
  "target.fileNameErrorModalOverLength": "Upload up to {{maxLength}} images",
  "target.fileNameErrorModalNameSizeTitle": "You have a file name longer than {{nameMaxSize}} characters",
  "target.fileNameErrorModalNameSizeDescription": "{{NameMaxSize}}File name should not exceed over 100 characters. \n Edit file name and upload again. ",
  "target.fileNameErrorTypeTitle": "The file format of uploaded image / asset is not supported",
  "target.fileNameErrorTypeDescription": "Supported image format : {{acceptType}}\nspecial characters: Special characters other than -,_ cannot be used in the file name.",
  "target.addtarget.heading.title": "Add Target",
  "target.addtarget.heading.description": "Upload AR marker image to test AR augmentation ",
  "target.addtarget.heading.imageguide": "Image guide",
  "target.addtarget.heading.tutorial": "tutorial",
  "target.addtarget.imagupload.title": "Image Upload",
  "target.addtarget.imagupload.description": "Select or drag and drop the file to upload.",
  "target.addtarget.imagupload.btn_addfile": "Add file",
  "target.addtarget.notice.title": "Tips for good marker image",
  "target.addtarget.notice.list0": "Supported image format : {{acceptType}} <br> (Chrome or Firefox does not support .tif preview/ Chrome, Firefox and Safari does not support .heic preview)",
  "target.addtarget.notice.list1": "Cannot upload the filename containing special characters other than '-, _.",
  "target.addtarget.notice.list2": "Validation test for image target",
  "target.addtarget.notice.list3": "PV will not be charged for tests and previews",
  "target.addtarget.checklist.good.title": "Good image",
  "target.addtarget.checklist.good.list0": "Image with lots of feature points and noticeable details",
  "target.addtarget.checklist.good.list1": "High contrast image",
  "target.addtarget.checklist.good.list2": "Plain flat image",
  "target.addtarget.checklist.caution.title": "Attention",
  "target.addtarget.checklist.caution.list0": "Image with lots of repeated patterns",
  "target.addtarget.checklist.caution.list1": "Image unclear or too bright",
  "target.addtarget.checklist.caution.list2": "Image with low resolution(glossy or dark)",
  "target.addtarget.modaladd.title": "Add Target",
  "target.addtarget.modaladd.featuretext": "You can upload multiple images",
  "target.addtarget.modaladd.errortext.text": "Supported image format : ({{acceptFile}})\n(Chrome or Firefox does not support .tif preview)\n(Chrome, Firefox and Safari does not support .heic preview)\nCannot upload the filename containing special characters other than '-, _.",
  "target.view.modifytitle.title": "New title",
  "target.view.modifytitle.modify": "Edit title",
  "target.view.modifyTargetImage.title": "Replace a target image",
  "target.view.modifyTargetImage.desc": "Select a new target image",
  "target.view.modifytitle.placeholder": "Enter new title",
  "target.view.modifytitle.errormessage": "Can enter max 30 letters.(Korean, alphabet, numbers, special characters such as -,_ allowed)",
  "target.view.testway.title": "Test method",
  "target.view.testway.description": "Original image will not be stored.\n Recommended to test AR augmentation with the actual size.",
  "target.view.testway.list0": "Scan the QR code with your smartphone.",
  "target.view.testway.list1": "Camera is activated in the browser.",
  "target.view.testway.list2": "Once AR test on camera is validated, you can use the image as a marker image.",
  "target.view.testway.list3": "Even if detection is successful, the image still may be inadequate as a marker due to degraded detection rates or shaking. \n In this case, the image needs to be replaced or edited to gain improved stability.",
  "target.view.deleteimage": "Delete image",
  "target.view.replaceTargetImage": "Replace Target Image",
  "target.rating.one.noimage": "You can check out the image once it is saved",
  "target.rating.congruence.pass": "Image is adequate to use as a marker",
  "target.rating.congruence.notpass": "Image is inadequate to use as a marker",
  "target.rating.modify.title": "Title",
  "target.rating.modify.placeholder": "enter file name",
  "target.rating.ratingtable.td0": "Image",
  "target.rating.ratingtable.td1": "Title",
  "target.rating.ratingtable.td2": "Rating",
  "target.rating.ratingtable.td3": "Adequacy",
  "target.rating.ratingtable.td4": "Width\n(millimeter)",
  "target.rating.ratingtable.td5": "Delete",
  "target.rating.one_congruence.pass": "Adequacy",
  "target.rating.one_congruence.notpass": "Inadequate",
  "target.rating.multi_congruence.pass": "Adequacy",
  "target.rating.multi_congruence.notpass": "Inadequate",
  "target.rating.deleteitem": "Delete",
  "target.targetlist.title": "Target list",
  "target.targetlist.allcount": "A total of {{allCount}} sets",
  "target.search.placeholder": "Searching Target image",

  // 2차 추가분
  "target.addtarget.width": "Width (millimeter)",
  "target.modaldelete.title": "Delete image",
  "target.modaldelete.description": "The image cannot be restored once deleted",
  "target.targetlist.emptytext": "No image registered.",
  "payment_history.emptytext": "No payment history",

  //3차 추가분
  "target.addtarget.errorWidthReg": "Please enter numbers only.",
  "target.addtarget.errorWidthEmpty": "please enter the width",
  "paymenthistory.view_detail_mobile": "View",
  "paymenthistory.view_detail_moretablet": "View billing details",
  "paymenthistory.search_period": "Search period",
  "paymenthistory.search.all": "All",
  "target.view.modal_copy_success": "Copied.",
  "license.downgrade_notice": "plan will be downgraded on {{downgradeDate}}.",
  "license.free_notice": "The project's view count will be initialized on {{downgradeDate}}.",

  //4차 추가분
  "dashboard.pvchart.error_period_title.over_max": "Maximum viewing period is {{maxRange}} years.",
  "dashboard.pvchart.error_period_title.wrong_selected": "Please change the specified date.",
  "license.heading_preparing_downgrade": "Downgrade feature will be ready soon.",

  //추가분 이후
  "target.view.modal_title_update_success": "The target image title has been updated.",
  "sort.updated": "Updated",
  "sort.created": "Created",
  "sort.highPv": "Highest PV Count",
  "sort.projectName": "Project Name",
  "sidebar.tutorial.link": "https://letsee14.notion.site/Eng-Letsee-WebAR-SDK-Tutorial-ae04d41a32244bcd91e444f504f968bd",
  "openBeta.title.01": "Letsee Creator",
  "openBeta.title.02": "Now in ",
  "openBeta.title.03": "free",
  "openBeta.title.04": " open beta",
  "openBeta.texts.01": "Try using Letsee Creator for free during the open beta.\nYou can create up to 3 projects for free. ",
  "openBeta.texts.02": "The specific pricing plan will be announced later.\nDuring the open beta period, we adjust the price policy by\nreflecting the feedbacks of the test users.",
  "openBeta.popup.01": "Do not show for 24h",
  "openBeta.popup.02": "Do not show again",
  "fbxToGlb.header.01": "Convert FBX file into GLB file",
  "fbxToGlb.header.02": "You can convert a 3d file made of FBX into a GLB file format for use with Letsee WebAR.",
  "fbxToGlb.start.01": "Start Converting",
  "fbxToGlb.dropbox.01": "Drag & Drop your FBX file here\n(file below 10mb)",
  "fbxToGlb.dropbox.02": "or",
  "fbxToGlb.dropbox.03": "Click here to upload FBX file\n(size below 10mb)",
  "fbxToGlb.progress.01": "FBX 파일 등록중...(1/3)",
  "fbxToGlb.progress.02": "GLB로 변환중...(2/3)",
  "fbxToGlb.progress.03": "다운로드 준비중...(3/3)",
  "fbxToGlb.progress.complete": "변환이 완료되었습니다.",
  "fbxToGlb.progress.fail": "죄송합니다, 다시 시도해주세요.",
  "fbxToGlb.progress.fail.button": "다른 파일로 다시 시도하기",
  "fbxToGlb.finish.download": "GLB 파일 내려받기",
  "fbxToGlb.finish.again": "다른 파일 이어서 변환하기",
};
