import {
  GET_TARGET_ITEM_META_REQUEST,
  GET_TARGET_ITEM_META_SUCCESS,
  GET_TARGET_ITEM_META_ERROR,
  UPDATE_TARGET_ITEM_META_TITLE_REQUEST,
  UPDATE_TARGET_ITEM_META_TITLE_SUCCESS,
  UPDATE_TARGET_ITEM_META_TITLE_ERROR, INIT_TARGET_ITEM_META
} from '../actions'

export const initTargetItemMeta = () => ({
  type: INIT_TARGET_ITEM_META
})

export const getTargetItemMetaRequest = (imageId) => ({
  type: GET_TARGET_ITEM_META_REQUEST,
  payload: { imageId }
})

export const getTargetItemMetaSuccess = (result) => ({
  type: GET_TARGET_ITEM_META_SUCCESS,
  result: result.data
})

export const getTargetItemMetaError = (error) => ({
  type: GET_TARGET_ITEM_META_ERROR,
  error: error.response.data
})

export const updateTargetItemMetaTitleRequest = (data) => ({
  type: UPDATE_TARGET_ITEM_META_TITLE_REQUEST,
  payload: {
    imageId: data.imageId,
    title: data.title
  }
})

export const updateTargetItemMetaTitleSuccess = (result) => ({
  type: UPDATE_TARGET_ITEM_META_TITLE_SUCCESS,
  result: {title: result}
})

export const updateTargetItemMetaTitleError = (error) => ({
  type: UPDATE_TARGET_ITEM_META_TITLE_ERROR,
  error: error.response.data
})
