import {
  GET_TARGET_LIST_REQUEST,
  GET_TARGET_LIST_SUCCESS,
  GET_TARGET_LIST_ERROR,
  INIT_TARGET_LIST,
  UPDATE_TARGET_LIST_CURRENT_PAGE
} from '../actions';

export const initialState = {
  page: 1,
  pageSize: 8,
  totalCount: 0,
  pageCount: 1,
  data: [],
  isLoading: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_LIST_REQUEST:
      return {...state, isLoading: true, error: ''};
    case GET_TARGET_LIST_SUCCESS:
      return {...state, ...action.result, isLoading: false, error: ''};
    case GET_TARGET_LIST_ERROR:
      return {...state, isLoading: false, error: action.error}
    case INIT_TARGET_LIST:
      return initialState
    case UPDATE_TARGET_LIST_CURRENT_PAGE:
      return {...state,  isLoading: true, page: action.payload.currentPage}
    default:
      return {...state};
  }
}