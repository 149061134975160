import {
  defaultDirection, fileUploadAcceptType, fileUploadMaxLength,
  MaxLetterPassword,
  MinLetterPassword,
  PasswordRegularExpression
} from "../constants/defaultValues";
import validator from "validator";
import React, {Fragment} from "react";
import {isValidPhoneNumber} from "react-phone-number-input";
import moment from "moment";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const isPrepareNextPayment = (nextPaymentDate, isFree) => {
  if (isFree) return false

  const today = new Date();

  const fiveDateBeforeNextPaid = new Date(nextPaymentDate.getTime());
  fiveDateBeforeNextPaid.setDate(fiveDateBeforeNextPaid.getDate() - 5);

  return today >= fiveDateBeforeNextPaid;
};

export const createFormData = (key, files, filename) => {
  let formData = new FormData()
  files.forEach((value) => {
    formData.append(key, value, filename)
  })
  return formData
}

export const createFilename = (files) => {
  return files.map(el => el.name).join(', ')
}

export const changeFileName = (files) => {
  return files.map(file => {
    if(file.name.length > 30) {
      const filenameEx = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
      return new File([file], `${file.name.substring(0, 30) + filenameEx}`, {type: file.type})
    }
    return file
  })
}

export const validateFileNameReg = (filename) => {
  const filenameReg = /[\p{L}\p{Nd}\p{Pc}\p{Pd}]/gu;
  const filenameWithoutEx = filename.lastIndexOf('.') !== -1 ? filename.substring(0, filename.lastIndexOf('.')) : filename
  if((filenameWithoutEx.match(filenameReg) === null) || (filenameWithoutEx.match(filenameReg).length !== filenameWithoutEx.length)) {
    return {
      result: false,
      message: 'target.fileNameValidateErrorName'
    }
  }
  return {
    result: true,
    message: ''
  }
}

export const validateFileNameLength = (filename) => {
  const filenameWithoutEx = filename.lastIndexOf('.') !== -1 ? filename.substring(0, filename.lastIndexOf('.')) : filename
  if(filenameWithoutEx.length <= 0) {
    return {
      result: false,
      message: 'target.fileNameValidateErrorEmptyName'
    }
  } else if(filenameWithoutEx.length > 30) {
    return {
      result: false,
      message: 'target.fileNameValidateErrorOverLength'
    }
  }
  return {
    result: true,
    message: ''
  }
}

export const validateWidth = (widthValue) => {
  if(widthValue.length <= 0) {
    return {
      result: false,
      message: 'target.addtarget.errorWidthEmpty'
    }
  }
  const widthReg = /[0-9.]/gi;
  if(widthValue.match(widthReg) && widthValue.match(widthReg).length === widthValue.length) {
    return {
      result: true,
      message: ''
    }
  }
  return {
    result: false,
    message: 'target.addtarget.errorWidthReg'
  }
}

export const validateFilesLength = (files, oldRatingListLength) => {
  const errorLength = Boolean((files.length + oldRatingListLength) > fileUploadMaxLength)
  if (errorLength) return false
  return true
}

export const validateFilesType = (files) => {
  const isErrorType = files.some(file => {
    return !fileUploadAcceptType.includes(file.type)
  })
  if (isErrorType) return false
  return true
}

export const validateFilesName = (files) => {
  const isErrorNameLength = files.some(file => {
    const {result} = validateFileNameLength(file.name)
    return !result
  })
  if(isErrorNameLength) return false
  const isErrorNameRex = files.some(file => {
    const {result} = validateFileNameReg(file.name)
    return !result
  })
  if(isErrorNameRex) return false
  return true
}

export const validateProjectName = (projectName) => {
  const projectNameReg = /[\p{L}\p{Nd}\p{Pc}\p{Pd}]/gu;
  if (!projectName) {
    return {
      result: false,
      message: 'project.nameValidationErrorEmpty'
    }
  } else if (projectName.length < 4 || projectName.length > 20) {
    return {
      result: false,
      message: 'project.nameValidationErrorNameSize'

    }
  } else if (!projectName.match(projectNameReg) || projectName.match(projectNameReg).length !== projectName.length) {
    return {
      result: false,
      message: 'project.nameValidationErrorName'
    }
  }
  return {
    result: true,
    message: ''
  }
}

export const validateProjectNameWithSpace = projectName => {
  console.log(projectName.charAt(0) === ' ')
  const projectNameReg = /[`~!@#$%^&*()|+=?;:'",.<>\{\}\[\]\\\/]/gu;
  if (!projectName) {
    return {
      result: false,
      message: 'project.nameValidationErrorEmpty'
    }
  } else if (projectName.charAt(0) === ' ' || projectName.charAt(projectName.length - 1) === ' ') {
    return {
      result: false,
      message: 'project.nameValidationErrorSpace'
    }
  } else if (projectName.length < 4 || projectName.length > 20) {
    return {
      result: false,
      message: 'project.nameValidationErrorNameSize'

    }
  } else if (projectName.match(projectNameReg)) {
    return {
      result: false,
      message: 'project.nameValidationErrorNameWithSpace'
    }
  }
  return {
    result: true,
    message: ''
  }
}

export const validateProjectDomain = (domain) => {
  if (!domain || !validator.isFQDN(domain)) {
    return {
      result: false,
      message: 'errors.empty_projectnotvalid'
    }
  }
  return {
    result: true,
    message: ''
  }
}

export const validateEmail = (value) => {
  const rex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!value || value === null) {
    return {result: false, message: 'errors.empty_email'}
  } else if (!rex.test(value)) {
    return {result: false, message: 'errors.invalid_email'}
  }
  return {result: true, message: ''}
}

export const validatePassword = (values) => {
  if (!values) {
    return {result: false, message: 'errors.empty_password'}
  } else if (values.length < MinLetterPassword || values.length > MaxLetterPassword) {
    return {result: false, message: 'errors.short_password'}
  } else if (!PasswordRegularExpression.test(values)) {
    return {result: false, message: 'errors.rule_password'}
  }
  return {result: true, message: ''}
}

/**
 * 컨폼 페스워드 유틸 추가
 * @Auther Seokyeon.lee
 * @param values
 * @param setError
 * @returns {{result: boolean, message: string}}
 */
export const validateConfirmPassword = (values,password) => {
  if (!values) {
    return {result: false, message: 'errors.empty_confirm_password'}
  } else if (values.length < MinLetterPassword || values.length > MaxLetterPassword) {
    return {result: false, message: 'errors.short_password'}
  } else if (!PasswordRegularExpression.test(values)) {
    return {result: false, message: 'errors.confirm_password'}
  } else if(password !== values){
    return {result: false, message: 'errors.confirm_password'}
  }
  return {result: true, message: ''}
}

export const validatePhone = (number) => {
  if (number === '' || number === undefined || !isValidPhoneNumber(number)) {
    return false
  }
  return true
}

export const transitionText = (value) => {
  if(typeof value === 'string') {
    return value.split('\n').map((item, index) => {
      return <Fragment key={index}>{item}{index !== (value.split('\n').length - 1) && <br />}</Fragment>
    })
  }
  return value
}

export const copyClipBoard = (target) => {
  const tmpTextarea = document.createElement('textarea');
  tmpTextarea.value = target;
  document.body.appendChild(tmpTextarea);
  tmpTextarea.select();
  tmpTextarea.setSelectionRange(0, 9999);  // 셀렉트 범위 설정
  document.execCommand('copy');
  document.body.removeChild(tmpTextarea);
}

export const transitGetTime = (stringDate) => {
  const dateArray = stringDate.split('-')
  return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]).getTime()
}

export const pvGraphColor = (grade) => {
  switch (grade) {
    case 'starter' :
      return 'rgba(65, 124, 221, .2)'
    case 'advance' :
      return 'rgba(158, 92, 224, .2)'
    case 'professional' :
      return 'rgba(105, 58, 196, .2)'
    case 'enterprise' :
      return 'rgba(165, 129, 61, .2)'
    default :
      return 'rgba(139, 138, 144, .2)'
  }
}

export const viewErrorByEnv = (error) => {
  if(process.env.NODE_ENV === 'development') {
    throw error
  } else {
    console.log(error)
  }
}

export const getEditorUrl = (projectId) => {
  return `${process.env.REACT_APP_CREATOR_ROOT_URL}/?id=${projectId}`;
}

export const getEditorDemoUrl = (projectId) => {
  return `${process.env.REACT_APP_CREATOR_DEMO_ROOT_URL}/?id=${projectId}`
}

export const sortArrayByKey = (array, key, pvArray) => {
  const copied = [...array]
  switch (key) {
    case 't':
      // sort by title
      copied.sort((a, b) => {
        const A = a.title.toUpperCase()
        const B = b.title.toUpperCase()
        return A < B ? -1 : A > B ? 1 : 0
      })
      break
    case 'c':
      // sort by createdAt
      copied.sort((a, b) => {
        return moment(b['createdAt']).format('X')-moment(a['createdAt']).format('X')
      })
      break
    case 'u':
      // sort by updatedAt
      copied.sort((a, b) => {
        return moment(b['updatedAt']).format('X')-moment(a['updatedAt']).format('X')
      })
      break
    case 'p':
      // sort by viewcount
      if (pvArray === undefined) return copied
      copied.filter(project => Object.assign(project, pvArray.find(p => p.id === project.id)))
      copied.sort((a, b) => {
        const A = a.viewcount
        const B = b.viewcount
        return A < B ? 1 : A > B ? -1 : 0
      })
      break
    default:
      // sort by updatedAt
      copied.sort((a, b) => {
        return moment(b['updatedAt']).format('X')-moment(a['updatedAt']).format('X')
      })
      break
  }

  return copied
}
