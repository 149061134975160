import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_USER_REQUEST,
  REGISTER_USER_REQUEST,
  LOGOUT_USER,
  CHECK_USER_REQUEST
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  checkUserSuccess,
  checkUserError,
  registerUserSuccess,
  registerUserError,
  logoutUser,
  resetPasswordValidate,
  resetUserInfo
} from './actions';
import i18n from "../../plugins/i18n"
import {
  defaultLocale,
  localeOptions
} from "../../constants/locale";
import {
  registerUser,
  login,
  sendAuthMail,
  getUserInfo
} from "../../api/users";
import {viewErrorByEnv} from "../../helpers/Utils";

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER_REQUEST, loginWithEmailPasswordSaga);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await login({email, password})
  let loginUser = {};
  loginUser.message = false;
  loginUser.user_id = response.data.user.id;
  loginUser.token = response.data.token;
  loginUser.user_email = response.data.user.email;
  loginUser.userInfo = response.data.user;
  return loginUser
}

function* loginWithEmailPasswordSaga({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  let { link } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync,  email, password);
    if (loginUser.userInfo.status === 'drop') {
      loginUser.message = 'drop';
      yield put(loginUserError(loginUser.message));
      return;
    }
    if (!loginUser.userInfo.email_verified) {
      let value = {
        user_email: loginUser.user_email,
        token: loginUser.token,
        key: loginUser.userInfo.key_for_verify
      }
      sessionStorage.setItem('emailinfo', JSON.stringify(value));
      loginUser.message = 'notAuth';
      yield put(loginUserError(loginUser.message));
    } else if (loginUser.userInfo.status === 'password-reset-validate') {
      let value = { token: loginUser.token }
      sessionStorage.setItem('userinfo', JSON.stringify(value));
      loginUser.message = 'password-reset-validate';
      yield put(resetPasswordValidate(loginUser));
    } else {
      let value = {user_id: loginUser.user_id, user_email: loginUser.user_email, token: loginUser .token }
      sessionStorage.setItem('userinfo', JSON.stringify(value));

      /**
       * update user language
       */
      const {language} = loginUser.userInfo
      if (localeOptions.some(locale => locale.id === language)) {
        i18n.changeLanguage(language);
      } else {
        i18n.changeLanguage(defaultLocale);
      }
      yield put(loginUserSuccess(loginUser));
      // if (link === undefined) link = '/'; else link = '/project/workspace?key=' + link;
      // history.push(link);
      history.push('/')
    }
  } catch (error) {
    /** TODO: 백엔드 구조가 일부 바뀌면서 error가 객체로 오지않아 error.response.data를 읽지 못하는 문제 해결하기 */
    /** 임시로 에러 메시지 넣어놓음 23/06/20 */
    yield put(loginUserError("zz"))
    // if(error.response.data) {
    //   yield put(loginUserError(error.response.data.error))
    // } else {
    //   viewErrorByEnv(error)
    // }
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER_REQUEST, registerWithEmailPasswordSaga);
}

const registerWithEmailPasswordAsync = async (email, password, t) => {
  const response = await registerUser({ email, password})
  let registerUserInfo = {};
  if (response.data.success) {
    let sendMailHtml = {
      subject: t('register.mail.subject'), html:
        `
          <div style="background: #F8F8F9; padding:20px 0 0 0; font-family:Noto Sans KR, Nanum Gothic, Dotum, Helvetica, AppleGothic, Helvetica, Sans-serif; color:#333333">
            <div style="max-width:600px; margin:0 auto ">
              <table style="width:100%; background: #FFFFFF; border-collapse: collapse;">
                <tbody>
                  <tr>
                    <td style="padding:40px 20px 20px 20px; ">

                      <!--렛시 로고 클릭시 렛시 홈페이지로 이동-->
                      <div style="text-align: center;"><a href="https://www.letsee.io/ko/" target="_blank"><img src="https://cdn.letsee.io/signature/letsee_w.png" style="width:148px;" alt="${t('forgotpassword.mail.logoalt')}"></a></div>

                      <!-- 회원가입시 -->
                      <div style="text-align: center; font-size: 16px; padding:12px 20px 22px 20px;">
                        <p style="padding:0; margin:0;">${t('register.mail.desc1')}</p>
                        <p style="padding:0; margin:0; font-weight: bold; color: #483F96;">${t('register.mail.desc2')}</p>
                      </div>
                      <div style="background: #FAF8FF; padding:10px 20px 20px 20px; text-align: center;">
                        <p style="font-size: 14px;">${t('register.mail.desc3')}<br>
                         ${t('register.mail.desc4')}</p>

                        <!--버튼-->
                        <div style="padding:32px 0; width:100%; text-align: center;">
                          <a href="${window.location.origin}/user/login?key=${encodeURIComponent(response.data.user.key_for_verify)}" target="_blank" style="width:180px; height:37px; padding: 11px 40px 15px 40px; background: #483F96; -moz-border-radius:35px; -webkit-border-radius:35px; border-radius:35px; text-align: center;font-weight: bold; font-size:16px; color:#ffffff; text-decoration: none;">${t('register.mail.verifyaccount')}</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- 풋터 안내 내용과 이메일 링크 -->
              <div style="padding:20px; margin:0; color:#666666; line-height: 130%; text-align: center;">
                <p style="padding:0; margin:0; font-size:12px;">
                ${t('register.mail.footer1')}<br>
                ${t('register.mail.mailto', {mail_address: 'mailto:contact@letsee.io', inline_style: "style='font-weight: bold; text-decoration: underline; color: #666666;'"})}
                </p>
                <p style="padding:10px 0 0 0; margin:0; font-size:10px;">Copyright © <strong>Letsee</strong>, Inc. All rights reserved</p>
            </div>
            </div>
          </div>
              `
    }
    sendAuthMail(sendMailHtml, response.data.token)
    registerUserInfo.message = false;
  } else {
    registerUserInfo.message = 'registerfail';
  }
  return registerUserInfo;
}

function* registerWithEmailPasswordSaga({ payload }) {
  const { email, password } = payload.user;
  const {t} = payload;
  try {
    const response = yield call(registerWithEmailPasswordAsync, email, password, t);
    if(!response.message) {
      yield put(registerUserSuccess());
    } else {
      yield put(registerUserError(response.message));
    }
  } catch (error) {
    if(error.response.data) {
      yield put(registerUserError(error.response.data.error))
    } else {
      viewErrorByEnv(error)
    }
  }
}


export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutSaga);
}

function logoutSaga({ payload }) {
  const { history } = payload
  sessionStorage.removeItem('userinfo');
  put(logoutUser())
  history.push('/user/login')
}

export function* watchCheckUser() {
  yield takeEvery(CHECK_USER_REQUEST, getUserInfoWithUserIdSaga);
}

const getUserInfoWithUserIdAsync = async () => {
  const response = await getUserInfo()
  let loginUser = {};
  if (response.data.success) {
    loginUser.message = false;
    loginUser.userInfo = response.data.user;
    return loginUser;
  } else {
    loginUser.message = response.data.message;
    return loginUser;
  }
}

function* getUserInfoWithUserIdSaga({ payload }) {
  const { history } = payload;
  if (sessionStorage.getItem("userinfo") === null) {
    history.push('/user/login')
    return;
  }
  try {
    const loginUser = yield call(getUserInfoWithUserIdAsync);
    if (!loginUser.message && loginUser.message !== undefined) {

      if (!loginUser.userInfo.email_verified) {
        let value = {
          user_email: loginUser.userInfo.email,
          token: loginUser.token,
          key: loginUser.userInfo.key_for_verify
        }
        sessionStorage.setItem('emailinfo', JSON.stringify(value));
        loginUser.message = 'notAuth';
        yield put(loginUserError(loginUser.message));
        history.push('/user/login');

      }
      if (loginUser.userInfo.status === 'password-reset-validate') {
        sessionStorage.removeItem('userinfo');
        yield put(resetUserInfo());
        history.push('/user/login');
      }
      if (loginUser.userInfo.status === 'drop') {
        loginUser.message = 'drop';
        sessionStorage.removeItem('userinfo');
        yield put(loginUserError(loginUser.message));
        history.push('/user/login');
      } else {
        yield put(checkUserSuccess(loginUser))
      }
    } else {
      if (loginUser.message === 'Unauthorized') {
        sessionStorage.removeItem('userinfo');
        put(logoutUser())
        history.push('/user/login')
      }
      yield put(checkUserError(loginUser.message));
    }

  } catch (error) {
    if(error.response.data) {
      yield put(checkUserError(error.response.data.error))
    } else {
      viewErrorByEnv(error)
    }
    put(logoutUser())
    history.push('/user/login')
  }

}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchCheckUser),
  ]);
}
