/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  "general.copyright": "LetSee © 2019 All Rights Reserved.",
  "general.letsee": "㈜렛시",
  "general.email": "이메일",
  "general.won": "원",
  "general.mwon": "만원",
  "general.message": "Message",
  "general.success": "Success",

  /* 02.User Login, Logout, Register */
  "user.login-title": "로그인",
  "user.register": "회원가입",
  "user.forgot-password": "Forgot Password",
  "user.email": "email address",
  "user.email_hangul": "이메일",
  "user.password": "password",
  "user.password_re": "confirm password",
  "user.forgot-password-question": "비밀번호 찾기",
  "user.fullname": "Full Name",
  "user.login-button": "Login",
  "user.register-button": "Sign up",
  "user.reset-password-button": "비밀번호 변경",
  "user.forgot-password-button": "인증메일 요청",
  "user.buy": "BUY",
  "user.username": "이름",
  "user.service_policy": "서비스 이용약관",
  "user.info_policy": "개인정보 취급방침 ",
  "user.agree": "동의",
  "user.agree_terms_of_service_and_privacy_policy": '<a class="letsee_link letsee_link_underline" target="_blank" href="/user/service">서비스 이용약관</a>,  <a class="letsee_link letsee_link_underline" target="_blank" href="/user/privacy">개인정보 취급방침</a>,  <a class="letsee_link letsee_link_underline" target="_blank" href="/user/beta-service-policy">베타 서비스 이용약관</a> 동의',
  "user.cancel": "취소",
  "user.company": "소속(회사, 학교, 기관명)",
  "user.shortcompany": "소속",
  "user.policy_service": "서비스 이용 약관",
  "user.policy_privacy": "개인정보 취급 방침",
  "user.phone": "전화번호",
  "user.passwordchange": "비밀번호 변경",
  "user.placeholder_company": "소속정보샘플",
  "user.placeholder_password": "현재 비밀번호",
  "user.placeholder_newpassword": "새 비밀번호",
  "user.placeholder_confirmpassword": "새 비밀번호 확인",
  "forgotyourpassword.needemail": "인증메일 발송에 필요한e메일 주소를 입력해주세요",
  "logout.confirm": "로그아웃 하시겠습니까?",
  "logout.successfully": "로그아웃 되었습니다.",
  "user.save_email": "이메일 저장",
  "user.examplename": "이름을 입력해 주세요.",
  "user.companyinput": "회사, 학교, 기관명을 입력해 주세요.",
  "user.savednewpassword": "비밀번호 변경완료",

  /* 03.Menu */
  "menu.app": "Home",
  "menu.dashboards": "Dashboards",
  "menu.gogo": "Gogo",
  "menu.start": "Start",
  "menu.second-menu": "Second Menu",
  "menu.second": "Second",
  "menu.ui": "UI",
  "menu.charts": "Charts",
  "menu.chat": "Chat",
  "menu.survey": "Survey",
  "menu.todo": "Todo",
  "menu.search": "Search",
  "menu.docs": "Docs",
  "menu.blank-page": "Blank Page",
  "menu.dashboard": "대시보드",
  "menu.project": "워크스페이스",
  "menu.workspace": "워크스페이스",
  "menu.project_add": "프로젝트 생성",
  "menu.target_manager": "타겟 매니저",
  "menu.fbxToGlb": "FBX to GLB",
  "menu.target_image": "타겟 이미지",
  "menu.license": "라이선스 플랜",
  "menu.payment_history": "결제내역",
  "menu.setting": "설정",
  "menu.tutorial": "튜토리얼",
  "sub.license": "- 라이선스/결제",
  "sub.bill": "- 결제 내역",
  "sub.setting_member": "- 멤버",
  "sub.setting_key": "- 키",
  "menu.myinfo": "내 정보",
  "menu.logout": "로그아웃",

  /* 04.Error Page */
  "pages.error-title": "에러가 발견되었습니다. 접근할 수 없는 페이지입니다.",
  "pages.error-code": "Error code",
  "pages.go-back-home": "홈으로 이동",
  "errors.empty_email": "이메일 주소를 입력해주세요.",
  "errors.invalid_email": "이메일형식으로 입력해주세요.",
  "errors.invalid_email_register": "아이디는 이메일 형식 입니다",
  "errors.empty_password": "비밀번호를 입력해주세요.",
  "errors.empty_confirm_password": "새 비밀번호 확인을 입력해주세요.",
  "errors.short_password": "비밀번호는 최소 영문, 숫자, 특수 문자를 포함하여 8자리 이상 입력해 주세요.",
  "errors.rule_password": "비밀번호는 최소 영문, 숫자, 특수 문자가 1개 이상 포함되어야 합니다.",
  "errors.password_again": "새 비밀번호 확인하세요",
  "errors.confirm_password": "비밀번호가 일치하지 않습니다.",
  "errors.empty_projectname": "프로젝트 이름을 입력해주세요",
  "errors.empty_projectdomain": "프로젝트 도메인을 입력해주세요",
  "errors.notregistered": "등록되지 않은 이메일입니다.",
  "errors.loginfail": "로그인에 실패했습니다.",
  "errors.exist_email": "이미 존재하는 이메일입니다. ",
  "errors.register_fail": "회원가입에 실패했습니다.",
  "errors.notuser": "해당 이메일로 가입된 유저가 없습니다.",
  "errors.sameuser": "소유자 자신은 멤버 설정을 할 수 없습니다.",
  "errors.overinvitemember": "이미 초대된 멤버입니다. ",
  "errors.ownerrolechangefailr": "소유자의 역할은 변경할 수 없습니다.",
  "errors.ownercannotdeleted": "소유자의 역할은 삭제할 수 없습니다.",
  "errors.userisdroped": "탈퇴처리된 유저입니다.",
  "errors.passwordreset": "비밀번호가 초기화되었습니다.",
  "errors.passwordresetvalidate1": "비밀번호 유효성 검사 방법이 변경되었습니다.",
  "errors.passwordresetvalidate2": "새 유효성 검사 방법대로 비밀번호 변경하세요!",
  "errors.notargreepollicy": "서비스 이용약관, 개인정보 취급방침에 동의해 주세요.",
  "errors.notargreepollicy2":  "결제 이용약관 및 정기결제에 동의해 주세요.",
  "errors.projectnamevalid":  "프로젝트명을 4자 이상 20자 미만으로 입력해주세요",
  "errors.projectnameonlyenglish": "알파벳 및 숫자만 입력 가능합니다",
  "errors.empty_projectnotvalid": "유효한 도메인이 아닙니다.",
  "errors.needauthorize": "로그인이 필요한 페이지입니다.",
  "errors.phonenumbertoolong": "전화번호 형식이 다릅니다.",
  "errors.phonenumberinvalid": "틀린 포맷인 전화번호입니다",

  "notice.copied": "복사되었습니다.",
  "notice.copy.short": "인증키 복사",
  "notice.copied.short": "복사완료",

  "authkey.howtouse": "인증키 사용 방법",
  "auth_key.name": "인증키",
  "auth_key.copy_button": "인증키 복사",
  "auth_key.copy_complete": "인증키가 복사되었습니다.",

  "members.titlewaiting": "대기중",
  "member.waiting": "대기",

  "project.title": "참고해주세요",
  "project.help_message0": "프로젝트 생성 시 1개의 인증키가 발급되며, 소스 적용 후 AR콘텐츠를 개발 할 수 있습니다. ",
  "project.help_link0": "Tutorial 바로가기",
  "project.help_message1": "AR마커의 인식 횟수는 제한적이나, 유료 플랜으로 업그레이드 시 허용 횟수를 늘릴 수 있습니다. ",
  "project.help_link1": "상품안내 바로가기",
  "project.help_message2": "구매한 유료 플랜은 유료 플랜을 적용한 프로젝트에 귀속됩니다.",
  "project.help_message3": "프로젝트 삭제 시 유료 플랜의 정기 결제는 취소되며 익월부터는 과금 되지 않습니다.",
  "project.help_message4": "단, 당월 요금은 환불이 불가하오니, 삭제 시에는 신중하게 결정해주세요. ",
  "project.help_link2": "이용안내 바로가기",
  "project.label_add": "신규 프로젝트 만들기 ",
  "project.invite": "팀원을 초대하였습니다.",
  "project.nameforadding": "프로젝트명",
  "project.domainforadding": "사이트 도메인",
  "project.projectname_title": "프로젝트",
  "project.project_add_error": "Project Add Error",
  "project.project_count_more_than_3": "프로젝트는 최대 3개까지 생성할 수 있습니다.",

  "member.admin": "관리자",
  "member.member": "팀원",
  "member.pending": "대기 중",

  "forms.modifytitle": "내정보 관리",
  "forms.modifysuccess": "유저 정보가 수정되었습니다.",
  "forms.passwordinvalid": "비밀번호가 일치하지 않습니다.",
  "forms.error": "오류가 발생하였습니다. 다시 시도해주세요.",
  "forms.cancel": "취소",
  "forms.confirm": "확인",
  "forms.modify": "변경내용 저장",
  "forms.change": "수정",
  "forms.delete": "삭제",
  "forms.close": "닫기",
  "forms.unsubscribe": "해지",
  "forms.save": "저장",
  "forms.modify2": "변경",
  "forms.next": "다음",
  "forms.checklicensepolicy": "유료서비스 <linkItem>결제이용약관</linkItem> 동의",
  "forms.checklicenseregular": "매월 정기결제 되는 것에 동의",
  "form.emptyphonenumber": "전화번호를 입력해 주세요.",
  "form.errorphonenumber": "국가 또는 전화번호가 유효하지 않습니다.",

  "dashboard.only1image": "도메인 변경 시 기존에 작동 중인 AR콘텐츠에 장애가 발생할 수 있습니다.",
  "dashboard.domainnamechange": "도메인 이름 수정",
  "dashboard.notvaliddomain": "유효한 도메인이 아닙니다.",
  "dashboard.servicedomain": "서비스 도메인",
  "dashboard.projectpv": "프로젝트 PV",
  "dashboard.freepv": " PV 무료 제공",
  "dashboard.detailpv": "프로젝트 PV 상세",
  "dashboard.downloadexcel": "엑셀 다운로드",
  "dashboard.percpv": "초과 시 CPV당 ",
  "dashboard.upgrade": "업그레이드",
  "dashboard.domain_fail": "도메인이 문제가 있거나, 서버에 문제가 발생하여 변경에 실패했습니다. 잠시 후 다시 시도해주세요.",
  "dashboard.project_create": "프로젝트 생성일",
  "dashboard.pv_date": "날짜",
  "dashboard.pv_pv": "PV",

  "auth_key.titlecopy": "복사",
  "auth_key.youneedtochangekey": "아래 코드 블럭의 your_app_key를 복사한 실제 앱 키로 변경합니다.",
  "license.payment_title": "라이선스 플랜 신청",
  "license.usage_fee": "라이선스 사용 요금",
  "license.basic_fee": "라이선스 요금",
  "license.total_fee": "총이용료",
  "license.month_flat": "월정액",
  "license.base_pv_exceed_fee": "기본 PV 초과요금 (초과 CPV 000)",
  "license.discount_fee": "할인",
  "license.vat_fee": "부가세",
  "license.recent_year_fee": "최근 1년간 이용료 납부 내역",
  "license.fee_division": "구분",
  "license.billing_date": "결제일자",
  "license.product_name": "라이선스 구분",
  "license.base_fee": "라이선스 요금",
  "license.addtional_fee": "추가요금",
  "license.just_fee": "총 결제 금액",
  "license.only1image": "이용 중인 라이선스",
  "license.upgradelincense": "라이선스 업그레이드",
  "license.plzselectlicense": "예상 AR PV에 따른 플랜을 선택하세요 .",
  "license.pvislimited": "PV가 한계에 도달했습니다. 업그레이드가 필요합니다.",
  "license.youneedupgrade": "기본 제공 PV 초과 시에는 라이선스를 업그레이드 하세요",
  "license.regularpayment": "월 정기 결제 ",
  "license.whyoff": "결제 해제시, 당월 요금 까지만 청구되며 익월부터는 청구되지 않습니다.",
  "license.youcantrefund": "당월요금은 환불되지 않습니다.",
  "license.subscribe_benefit": "정기 구독을 신청하시면,  Letsee watermark 가 없는 서비스 이용이 가능합니다.",
  "license.plan": "라이선스 플랜",
  "license.licenseupgrade": "라이선스 업그레이드",
  "license.youpayvat": "만원(VAT포함)이 기본 청구됩니다.",
  "license.pvovermoremondy": "Page View 기본 제공량을 초과할 경우, 초과 요금이 부과됩니다.",
  "license.license": "현재 라이선스",
  "license.summary": "주문 요약",
  "license.whopay": "주문자 정보",
  "license.needname": "이름을 입력해주세요",
  "license.needphone": "전화번호를 입력해주세요",
  "license.needcompany": "회사, 학교,기관명을 입력해 주세요.",
  "license.needemail": "이메일을 입력해주세요",
  "license.policy": "약관확인",
  "license.claimmoney": "청구 요금",
  "license.monthclaimmoney": "월 청구요금",
  "license.moneyunit": "원",
  "license.usage_productname": "사용상품",
  "license.howtopay": "납부 방법 ",
  "license.desc": "안내문구 - ㈜렛시가 제공하는 WebAR SDK는 웹환경에서 작동하는 만큼, 아이폰이나 안드로이드 폰 구분없이 사용 가능하며 AR콘텐츠 제작자 입장에서는 AR기술인력이 없더라도 웹개발자와 웹디자이너에 의해 원활하게 AR콘텐츠를 제작할 수 있다는 점이 장점이다.",
  "license.address": "서울시 강남구 테헤란로 82길 15 8층 (주)렛시",
  "license.boss": "주식회사 렛시 대표이사",
  "license.titlefree": "무료",
  "license.cantsell": "상업적 용도 금지",
  "license.freeisnotlong": "제한범위 초과 시 유료플랜 전환 필요",
  "license.bigproject": "대형 프로젝트",
  "license.nego": "가격협의",
  "license.finalprice": "총 결제금액",
  "license.paymethod": "결제 수단",
  "license.titlebase": "기본",
  "license.everymonth": "매월 ",
  "license.pv_per_month": "매월 {{viewcount}} PV 제공",
  "license.agreepolicyplz": "이용약관 및 정기결제에 동의해주십시오.",
  "license.formisempty": "비어 있는 항목이 있습니다.",
  "license.paymentofftitle": "정기결제 해지",
  "license.paymentoffdesc": "정기결제을 원하신다면 다음 결제일  <bold>{{itemDate}}</bold>까지<br> Subscribe 버튼을 클릭 해주세요.",
  "license.dismisspayment": "정기 결제가 해제되었습니다.",
  "license.dismisspayment_description": "<bold>{{itemDate}}</bold> 이후 서비스는 무료로 전환되며<br>결제 정보는 삭제됩니다.",
  "license.cancelPayment": "라이선스 결제를 취소하시겠습니까?\n작성하신 내용은 저장되지 않습니다.",
  "license.trouble": "문제가 발생하였습니다. 잠시후 다시 시도해주세요.",
  "license.licensechanged": "정기결제 구독이 신청되었습니다.",
  "license.cannotupgrade": "현재 PV값 로딩에 문제가 발생했습니다. \n잠시 후 다시 시도해주십시오. ",
  "license.youcantchangelicense": "이 프로젝트는 라이센스를 변경할 수 없습니다.",
  "license.youwillchangelicense1": "라이센스가 ",
  "license.youwillchangelicense2": "에 변경 예정중이므로\n라이센스를 변경할 수 없습니다.",
  "license.youcantchangesamelicense": "같은 라이센스로는 변경할 수 없습니다.",
  "license.plzlatertry": "문제가 발생하였습니다.\n잠시 후 다시 시도하여 주세요",
  "license.pvisfullyouneedupgrade": "PV가 한계에 도달했습니다. 업그레이드가 필요합니다.",
  "license.pvisfullyouneedupgrade2": "- PV가 한계에 도달했습니다. 업그레이드가 필요합니다.",
  "license.pvisfullnotice": "[주의] PV를 모두 소진하면 서비스가 동작하지 않습니다.",
  "license.pvwillbeupgrade_title": "업그레이드",
  "license.pvwillbeupgrade_description": "소진되지 않은 <bold>{{itemCurrentGrade}} 플랜</bold>의 pv는 <boldNext>{{itemNextGrade}} 플랜</boldNext>의 pv와 합산됩니다.",
  "license.pvwillbeupgrade_description_forfree": "<bold>{{itemGrade}} 플랜</bold>으로 업그레이드 하시겠습니까?",
  "license.warningdowngrade_title": "다운그레이드",
  "license.warningdowngrade_description": "현재 이용중인 <bold>{{itemCurrentGrade}} 플랜</bold>은 <boldDate>{{itemDate}}</boldDate>까지 유지되며,<br>기간 만료 후 <boldNext>{{itemNextGrade}} 플랜</boldNext>으로 다운그레이드 됩니다.",
  "license.contactus_title": "Enterprise 문의",
  "license.contactus_textarea": "프로젝트 범위 및 사용 용도",
  "license.contactus_textarea_placeholder": "내용을 입력해 주세요.",
  "license.contactus_sent_title": "전송 완료",
  "license.contactus_sent_description": "Enterprise 문의가 전송되었습니다.",
  // "license.add_fee_viewcount": "전월 초과 CPV(+",
  // "license.cannotaccess": "접근할 수 없는 메뉴입니다.",
  // "license.wouldyouchangeplan": "Would you like to change your plan?",
  // "license.warningdowngrade1": "The plan you are currently using will remain until ",
  // "license.warningdowngrade2": "and will be downgraded to the",
  // "license.warningdowngrade3": "changed plan after expiration.",
  // "license.add_fee_viewcount": "the previous month exceeding CPV(+",
  // "license.cannotaccess": "This menu is not accessible.",
  "license.thisitemisdeleted": "접근할 수 없는 결제상품입니다.",
  "license.": "접근할 수 없는 결제상품입니다.",
  "license.licenseyouselcted": "선택한 라이선스 내용",
  "license.detail_label": "상세내용",
  "license.payment_success": "결제가 정상적으로 완료되었습니다.",
  "license.payment_fail": "결제에 실패하였습니다.",
  "license.payment_failcode": "실패코드 : ",
  "license.payment_failmsg": "카드사에 문의 바랍니다. ",
  "license.changeiscompletedTitle": "변경완료",
  "license.changeiscompleted": "<bold>{{itemGrade}} 플랜</bold>으로 변경되었습니다. ",
  "license.checkyourservice": "기존 서비스에 영향이 없는지 점검해주세요 ",
  "license.current_use": "현재 이용중",
  "license.buy": "지금 구입",
  "license.free_conversion": "무료 전환",
  "license.contact": "문의하기",
  "license.month": "월",
  "license.total_fee2": "총 금액",
  "license.basic_fee2": "라이선스 금액",
  "license.watermark": "Letsee Watermark ",
  "license.include": "포함",
  "license.notinclude": "제거",
  "license.everymonth2": "월간 ",
  "license.arpageview": "AR Page View 제공",
  "license.ar_pv_per_month": "{{viewCount}} view / month 까지 제공",
  "license.planselect": "플랜 선택",
  "license.update": " 업데이트",
  "license.notfree": "유료",
  "license.enterprise": "월간 100,000 AR Page View 이상 제공 시",
  "license.contactus": "별도 문의",
  "license.detail_page": "세부 내역",
  "license.detail_link": "내용보기",
  "license.detail_link.paypal": "페이팔 계정에서 결제 내역 상세 보기",
  "license.enterprise_arview": "맞춤형 AR Page View 제공",
  "license.separate_inquiry": "별도문의",
  "license.payer": "결제자",
  "license.phonenumber": "전화번호",
  "license.project_last_payment_date": "마지막 결제일",
  "license.project_next_payment_date": "프로젝트 다음 결제일",
  "license.confirm_redirect_to_paypal": "결제 진행하기 위해 하기 결제 수단을 선택하세요!",
  "license.no_paypal_plan_id": "이 제품의 페이팔을 통한 결제가 불가합니다.\n상세 정보를 더 알고 싶다면 어드민에게 연락해 주시길 바랍니다!",
  "license.perview_avaliable": "사용 가능",
  "license.link_license": "플랜 상세보기",
  //'license.link_license': 'View plan details',
  "license.grade_free_type": "체험판",
  "license.grade_starter_type": "소규모 프로젝트",
  "license.grade_advance_type": "중소형 프로젝트",
  "license.grade_professional_type": "중대형 프로젝트",
  "license.grade_enterprise_type": "대형 프로젝트",
  "license.support_session": "세션 제공",
  "license.enterprise_benefit": "맞춤형 세션 제공을 위한<br>기업용 라이선스",
  "license.support_pv_alert": "10%미만 남았을시 alert 메일 전송",
  "license.support_report": "고급 분석 리포트 제공",
  "license.support_email": "이메일 기술지원",

  "payment_history.title": "라이선스 결제내역",
  "payment_history.th_date": "결제일",
  "payment_history.th_plan": "라이선스 플랜",
  "payment_history.th_fee": "라이선스 요금 (부과세 포함)",
  "payment_history.th_payer": "결제자",
  "payment_history.th_page": "청구서",

  "setting.title_project": "프로젝트 관리",
  "setting.change_title_project": "프로젝트명 수정하기",
  "setting.change_title_project_placeholder": "변경하실 프로젝트명을 입력",
  "setting.changed_title": "프로젝트 이름이 변경되었습니다.",
  "setting.change_domain": "도메인 수정하기",
  "setting.changed_domain_caution_title": "도메인 수정에 유의해 주세요!",
  "setting.changed_domain_caution_description": "도메인 변경시 기존에 작동중인\nAR 콘텐츠에 장애가 발생할 수 있습니다.",
  "setting.changed_domain": "도메인 주소가 변경되었습니다.",
  "setting.titleprojectdelete": "프로젝트 삭제하기",
  "setting.delete_notice_title": "삭제된 프로젝트는 다시 복구하실 수 없습니다.",
  "setting.deletedesc0": "프로젝트를 삭제하면, 라이선스 정기결제가 자동으로 취소됩니다.",
  "setting.deletedesc1": "기존에 운영중인 서비스가 중단 되오니, 신중하게 결정해 주시기 바랍니다.",
  "setting.deletedesc2": "이미 삭제한 프로젝트는 복구할 수 없습니다.",
  "setting.askdelete": "정말로 삭제하시겠습니까?",
  "setting.askdelete_placeholder": "DELETE 문자를 입력해주세요.",
  "setting.askdelete_error": "‘DELETE’를 정확하게 입력해 주세요.",
  "setting.deleted_project": "프로젝트가 삭제되었습니다.",
  "setting.title_appkey": "인증키 관리",
  "setting.manage_appkey_notice": "주의) OFF 설정 시, 프로젝트에 해당되는 모든 콘텐츠의 작동이 중단됩니다.<br>사용에 유의해 주세요",
  "setting.manage_appkey_stop_title": "인증키 OFF",
  "setting.manage_appkey_stop_description": "해당 프로젝트 서비스 사용이 정지됩니다.",
  "setting.manage_appkey_start_title": "인증키 ON",
  "setting.manage_appkey_start_description": "해당 프로젝트 서비스 사용이 가능합니다.",
  "setting.member_invite_title": "이메일 주소를 입력하여 팀원을 초대할 수 있습니다",
  "setting.member_invite_role": "할당할 역할",
  "setting.invitesuccess": "프로젝트에 초대되었습니다.",
  "setting.success": "수정되었습니다",
  "setting.deletesuccess": "삭제되었습니다",
  "setting.statuson": "인증키가 ON처리되었습니다.",
  "setting.statusoff": "인증키가 OFF처리되었습니다.",
  "setting.realydelete": "정말로 삭제하시겠습니까? 기존에 운영중인 서비스는 중단됩니다. DELETE 문자를 입력해주세요.",
  "setting.changecomplete": "변경되었습니다.",
  "setting.keyoffmessage": "인증키 OFF시 프로젝트에 연관된 모든 콘텐츠의 사용이 중단됩니다",
  "setting.titlechange": "변경",
  "setting.titlesave": "저장",
  "setting.titlecancel": "취소",
  "setting.keyonoff": "인증키 ON / OFF",
  "setting.titledoit": "하기",
  "setting.titleprojectnamechange": "프로젝트 이름 변경",
  "setting.reallydelete": "정말 삭제하시겠습니까?",
  "setting.addnewmember": "새로운 멤버 추가",
  "setting.projectmembersetting": "프로젝트 멤버 설정",
  "setting.project_auth": "프로젝트 권한",
  "setting.member": "팀원",
  "setting.admin": "관리자",
  "setting.accessdashboard": "Dashboard, 인증키, AR마커 테스트 메뉴 접근",
  "setting.accesslicense": "라이선스/결제 메뉴 접근",
  "setting.acesssetting": "설정 메뉴 접근",
  "setting.accessauth": "권한 설정",
  "setting.addmember": "팀원으로 추가",
  "setting.addadmin": "관리자로 추가",
  "setting.division": "구분",
  "setting.auth": "권한",
  "setting.recentlogin": "최근 로그인",
  "setting.waitasmember": "팀원으로 대기",
  "setting.waitasadmin": "관리자로 대기",
  "setting.youcantaccess": "팀원은 프로젝트 생성, 라이선스/결제 메뉴, 설정 메뉴 접근이 제한됩니다.",
  "setting.titledelete": "삭제",
  "setting.titlesetting": "설정",
  "setting.invite_mail.subject": "[Letsee] 렛시 프로젝트에 초대되었습니다.",
  "setting.invite_mail.desc1": "Letsee for Developers 프로젝트의",
  "setting.invite_mail.desc2": "관리자 or 팀원으로 초대되었습니다.",
  "setting.invite_mail.desc3": "아래 버튼을 클릭하시면 초대가 완료되며",
  "setting.invite_mail.desc4": "Letsee for Developers 서비스로 이동됩니다.",
  "setting.invite_mail.acccept_invitation": "초대 수락하기",

  "resetpassword.changecomplete": "비밀번호 변경완료",
  "resetpassword.login": "로그인 해 주세요.",
  "resetpassword.mail.subject": "[Letsee] 비밀번호를 설정해주세요.",
  "resetpassword.mail.title_desc1": "Letsee for Developers 사이트의",
  "resetpassword.mail.body_desc1": "계정이 잠금 조치 되었습니다.",
  "resetpassword.mail.body_desc2": "계정을 인증하시면, 잠금 조치가 해제됩니다.",
  "resetpassword.mail.body_desc3": "계정보호를 위해 패스워드를 변경해주세요",
  "resetpassword.mail.body_verifylink": "<a href='{{link}}' >계정 인증하기</a>",
  "resetpassword.mail.body_verifyText": "계정 인증하기",
  "resetpassword.resetpassword_susscess": "Reset Password Success",

  "forgotpassword.mail.subject": "[Letsee] 비밀번호를 설정해주세요.",
  "forgotpassword.mail.logoalt": "렛시로고",
  "forgotpassword.mail.desc1": "Letsee for Developers 사이트의",
  "forgotpassword.mail.desc2": "비밀번호를 다시 설정해 주세요.",
  "forgotpassword.mail.desc3": "아래 버튼을 클릭하여 비밀번호를 재설정 해주세요.",
  "forgotpassword.mail.desc4": "Letsee for Developers 서비스로 이동됩니다.",
  "forgotpassword.mail.changepass": "비밀번호 변경하기",
  "forgotpassword.mail.redirectmail": "이메일",
  "forgotpassword.mail.footer1": "본 메일은 발송전용으로 회신되지 않습니다.",
  "forgotpassword.mail.mailto": "문의는 <a href='{{mail_address}}' {{inline_style}}'>이메일</a>을 통해서 남겨주세요.", //문의는 이메일을 통해서 남겨주세요.
  "forgotpassword.forgot_password_success": "Forgot Password Success",
  "forgotpassword.forgot_password_error": "Forgot Password Error",
  "forgotpassword.please_check_your_email": "Please check your email.",
  "forgotpassword.please_login_with_your_new_password": "Please login with your new password.",
  "forgotpassword.sent_email1": "인증 메일이",
  "forgotpassword.sent_email2": "으로 발송 되었습니다.",

  "register.youneedpassword": "비밀번호를 입력해주세요.",
  "register.youneedpasswordconfirmation": "비밀번호를 한번 더 입력해주세요.",
  "register.youneedemail": "이메일을 입력해주세요.",
  "register.thankyou": "감사합니다.",
  "register.eamilsent": "계정 인증 이메일을 발송했습니다.",
  "register.sent_email1": "인증 메일이",
  "register.sent_email2": "으로 발송 되었습니다.",
  "register.youneedauthemail": "인증 후 가입이 완료됩니다.",
  "register.mail.subject": "[Letsee] 계정을 인증해 주세요",
  "register.mail.desc1": "Letsee for Developers 사이트의",
  "register.mail.desc2": "회원가입이 신청 되었습니다.",
  "register.mail.desc3": "아래 버튼을 클릭하여 계정 인증을 완료해 주세요.",
  "register.mail.desc4": " Letsee for Developers 서비스로 이동됩니다.",
  "register.mail.verifyaccount": "계정 인증하기",
  "register.mail.footer1": "본 메일은 발송전용으로 회신되지 않습니다.",
  "register.mail.mailto": "문의는 <a href='{{mail_address}}' {{inline_style}}'>이메일</a>을 통해서 남겨주세요.", //문의는 이메일을 통해서 남겨주세요.
  "register.signup_error": "SignUp Error",

  "login.authmail": "인증 메일이",
  "login.mailto": " 으로",
  "login.emailresent": "재발송 되었습니다.  ",
  "login.authmailiswaiting": "인증 대기중 입니다.",
  "login.afterauthlogin": "계정 인증 후 로그인 가능합니다.",
  "login.resent_email_plz": "인증 메일 재발송",
  "login.authcomplete": "계정 인증 완료되었습니다. ",
  "login.youneedloginin": "로그인 후 사용하실 수 있습니다.",
  "login.havenotletsee": "계정이 없으신가요?",
  "login.fotgotpassword": "비밀번호를 잊으셨나요?",
  "login.mailsent": "메일이 발송되었습니다.",
  "login.emailisnotexist": "가입된 이메일이 없습니다.",
  "login.willbelocked1": "비밀번호가 틀렸습니다. 다시 입력해주세요 5회 이상 틀릴 경우 계정이 잠깁니다.1/5",
  "login.willbelocked2": "비밀번호가 틀렸습니다. 다시 입력해주세요 5회 이상 틀릴 경우 계정이 잠깁니다.2/5",
  "login.willbelocked3": "비밀번호가 틀렸습니다. 다시 입력해주세요 5회 이상 틀릴 경우 계정이 잠깁니다.3/5",
  "login.willbelocked4": "비밀번호가 틀렸습니다. 다시 입력해주세요 5회 이상 틀릴 경우 계정이 잠깁니다.4/5",
  "login.willbelocked5": "비밀번호가 틀렸습니다. 다시 입력해주세요 5회 이상 틀릴 경우 계정이 잠깁니다.5/5",
  "login.mailcantsend": "현재 메일을 보낼 수 없습니다.\n잠시 후 다시 시도해주십시오.",
  "login.passwordfiveerror": "비밀번호 입력 횟수 5회 초과로\n 계정이 잠금 조치 되었습니다.  ",
  "login.unlockaccount": "계정 잠금 해제",
  "login.confirm": "확인",
  "login.authmail.subject": "[Letsee] 계정을 인증해 주세요",
  "login.authmail.body_desc1": "회원 가입 신청에 감사드립니다.",
  "login.authmail.body_desc2": "아래의 링크를 클릭하시면, 계정 인증이 완료됩니다.",
  "login.authmail.body_verifylink": "<a href='{{link}}'> 계정 인증하기</a>",
  "login.authmail.body_verifyText": "계정 인증하기",

  "project.ruleName": "프로젝트 명은 4자 이상 20자 이내로 입력해주세요. (특수문자는 - , _ 만 가능)",
  "project.nameValidationErrorSpace": "첫 줄 또는 마지막 줄에는 공백이 허용되지 않습니다.",
  "project.nameValidationErrorName": "특수문자는 - , _ 만 가능, 수정 후 입력해주세요.",
  "project.nameValidationErrorNameWithSpace": "특수문자는 - , _ 만 가능, 수정 후 입력해주세요.",
  "project.nameValidationErrorNameSize": "4자이상 20자 이내로 입력해주세요.",
  "project.nameValidationErrorEmpty": "프로젝트명을 입력해주세요.",
  "project.needdomain": "www.example.com",
  "project.adderror": "오류가 발생하여 프로젝트를 만들 수 없습니다.",
  "project.notexistfreeplan": "프리 플랜이 존재하지 않습니다.\n관리자에게 문의하십시오.",

  "modify.present_password": "현재 비밀번호",
  "modify.new_password": "새 비밀번호",
  "modify.new_password_re": "새 비밀번호 확인",
  "modify.saved": "정보가 저장되었습니다.",
  "modify.notice": "새 비밀번호로 변경을 원하지 않으시면 기존 비밀번호만 입력 후 저장하시면 됩니다.",
  "modify.error": "Modify Error",
  "modify.success": "Modify Success",

  "markettest.only1image": "죄송합니다. 한번에 1개의 이미지만 업로드 가능합니다.",
  "markettest.2mcanupload": "2M 이하의 파일만 업로드 가능합니다.",
  "markettest.saved": "저장되었습니다.",
  "markettest.imagecantrestore": "이미지 삭제 후 복구 하실 수 없습니다.",
  "markettest.imagedragndrop": "업로드할 파일 선택 또는 드래그 앤 드롭",
  "markettest.beforeupload": "이미지 업로드전 참고하세요",
  "markettest.imagepixelstory": "640,000pixel 2MB이하의 JPG또는 PNG 이미지를 업로드 할 수 있습니다.",
  "markettest.youcantest": "본 테스트를 통해 이미지 타켓의 유효성 여부를 점검해 보실 수 있습니다.",
  // 'markettest.pvisnorelated' : '테스트는 라이선스로 제공되는 PV와 무관 합니다.',
  // 'markettest.pvisfifty' : '이미지당 PV는 50회로 제한됩니다',
  "markettest.imagelist": "이미지 타겟 목록",
  "markettest.imagemarkettest": "이미지 마커 테스트",
  "markettest.form": "형식",
  "markettest.size": "용량",
  "markettest.resolution": "크기",
  "markettest.savedday": "저장일",
  "markettest.howtotest": "테스트 방법",
  "markettest.desc1": "스마트폰으로 QR코드를 스캔 합니다",
  "markettest.desc2": "브라우저에서 카메라가 활성화 됩니다",
  "markettest.desc3": "좌측 이미지를 비춰주세요",
  "markettest.desc4": "“TEST OK” 라는 글자가 확인되면, 이미지 타겟으로 사용 가능합니다.",
  "markettest.desc5": "단, 인식에 성공하더라도",
  "markettest.desc6": "인식률이 떨어지거나 떨림 현상 발생 시",
  "markettest.desc7": "이미지 타겟으로써 부적합한 이미지일 수 있습니다.",
  "markettest.desc8": "이때는 다른 이미지로 교체하거나",
  "markettest.desc9": "이미지 보정을 통해 안정성을 높이는 작업이 필요합니다.",
  "markettest.desc10": "AR 마커 이미지 업로드",
  "markettest.desc11": "AR 마커로 사용될 이미지가 정상 작동 되는지 미리 점검해 보세요.",
  "markettest.dog": "개",
  "markettest.imagedeletetitle": "이미지 삭제",
  "markettest.delete": "삭제되었습니다.",
  "markettest.notfile": "파일 형식이 올바르지 않습니다.",
  "markettest.tolargeimage": "640,000pixel 이하의 이미지만 업로드 할 수 있습니다.",
  // 'markertest.imagedesce' : '이미지는 등록일로부터 30일간 보관됩니다.',
  "markettest.upload_message": "Upload Message",
  "markettest.upload_error": "Upload Error",

  "payment.additionalcpv": "추가 CPV",
  "payment.usenow": "현재 이용중",
  "payment.changeplan": "플랜 변경",
  "payment.permonth": "만원/월",
  "payment.infomation": "납부 정보",
  "payment.paymentbyautocard": "카드 자동 납부",
  "payment.paymentbyetc": "기타",
  "payment.failmsg": "결제에 실패하였습니다. 다시 한번 시도해주세요.",
  "letsee.foundationday": "2019년 10월 25일",
  "payment.paymentinfo_title": "선택하신 <LicenseGrade /> 플랜의 상세내용",
  "payment.incis": "이니시스",
  "payment.paypal": "페이팔",

  "form-components.start": "Start",
  "form-components.end": "End",

  "service.beta": "베타 서비스 이용약관",
  "service.policy": "서비스 이용약관",
  "service.privacy": "개인정보 취급방침",
  "service.paymentpolicy": "결제 이용약관",
  "server.cannotconnect": "서버와 통신할 수 없습니다.\n다시 한 번 시도해주세요.",

  "footer.servicepolicy": "서비스이용약관",
  "footer.privacypolicy": "개인정보취급방침",
  "footer.betaServicePolicy": "베타 서비스 이용약관",

  "footer.representative": "대표 : 안상철",
  "footer.representative_number": "대표번호 : {{number}}",
  "footer.business_registration_number": "사업자 등록번호 : {{number}}",
  "footer.business_address": "주소 : 서울특별시 강남구 테헤란로82길 15 8층",

  "placeholder.phonenumber": "전화번호를 입력하세요",

  "target.fileNameDefaultValidate": "파일명은 30자 이내로 입력해주세요. (특수문자는 - , _ 만 가능)",
  "target.fileNameValidateErrorEmptyName": "파일명을 입력해주세요.",
  "target.fileNameValidateErrorOverLength": "파일명이 30자가 넘는 파일이 있습니다.",
  "target.fileNameValidateErrorName": "특수문자는 - , _ 만 가능, 수정 후 입력해주세요.",
  "target.fileNameErrorModalOverLength": "최대 {{maxLength}}개까지 가능합니다.",
  "target.fileNameErrorModalNameSizeTitle": "업로드한 파일 중에\n파일명이 {{nameMaxSize}}자가 넘는 파일이 있습니다.",
  "target.fileNameErrorModalNameSizeDescription": "{{NameMaxSize}}자 이내로 파일명 수정 후 재 업로드 해주세요.",
  "target.fileNameErrorTypeTitle": "지원하지 않는 이미지 또는 파일명을 포함하고 있습니다.",
  "target.fileNameErrorTypeDescription": "지원 이미지 파일 : {{acceptType}}\n특수문자 : - , _ 외 특수문자 파일명 업로드 불가",
  "target.addtarget.heading.title": "Target list",
  "target.addtarget.heading.description": "AR 마커 이미지를 업로드하여 AR 증강이 정상 작동 되는지 미리 점검해 보세요.",
  "target.addtarget.heading.imageguide": "이미지 가이드",
  "target.addtarget.heading.tutorial": "tutorial",
  "target.addtarget.imagupload.title": "Image Upload",
  "target.addtarget.imagupload.description": "업로드할 파일 선택 또는 드래그 앤 드롭",
  "target.addtarget.imagupload.btn_addfile": "Add file",
  "target.addtarget.notice.title": "이미지 업로드전 참고하세요!",
  "target.addtarget.notice.list0": "지원 이미지 파일 : {{acceptType}}<br>(chrome과 firefox에서 tif는 미리보기 불가능/ chrome, firefox, safari에서는 heic 미리보기 불가능)",
  "target.addtarget.notice.list1": "-, _외 특수문자가 포함된 파일명 업로드 불가능",
  "target.addtarget.notice.list2": "이미지 타켓의 유효성 여부 테스트",
  "target.addtarget.notice.list3": "테스트는 라이선스로 제공되는 PV와 무관",
  "target.addtarget.checklist.good.title": "좋은 이미지",
  "target.addtarget.checklist.good.list0": "특징점과 디테일이 많은 이미지",
  "target.addtarget.checklist.good.list1": "고대비 이미지",
  "target.addtarget.checklist.good.list2": "평평한 평면 이미지",
  "target.addtarget.checklist.caution.title": "유의 사항",
  "target.addtarget.checklist.caution.list0": "반복적인 패턴이 들어간 이미지",
  "target.addtarget.checklist.caution.list1": "너무 밝은 조명을 비추거나, 불분명한 이미지",
  "target.addtarget.checklist.caution.list2": "저해상도 이미지 (광택 또는 흐림)",
  "target.addtarget.modaladd.title": "Add Target",
  "target.addtarget.modaladd.featuretext": "대량의 이미지 업로드가 가능합니다.",
  "target.addtarget.modaladd.errortext.text": "지원 이미지 파일 : {{acceptFile}}\n chrome과 firefox에서 tif는 미리보기 불가능\nchrome, firefox, safari에서는 heic 미리보기 불가능\n- , _ 외 특수문자가 포함된 파일명은 업로드 불가능",
  "target.view.modifytitle.title": "변경할 타이틀명",
  "target.view.modifytitle.modify": "타이틀 수정",
  "target.view.modifyTargetImage.title": "타겟 이미지 변경하기",
  "target.view.modifyTargetImage.desc": "타겟 이미지를 선택하세요",
  "target.view.modifytitle.placeholder": "변경할 타이틀명을 입력",
  "target.view.modifytitle.errormessage": "최대 30자 입력이 가능합니다. (한글, 영문, 숫자, 특수문자 가능)",
  "target.view.testway.title": "테스트 방법",
  "target.view.testway.description": "원본 이미지는 보관하지 않습니다.\nAR 증강 테스트는 실제 원본 사이즈로 테스트 하시길 권장합니다.",
  "target.view.testway.list0": "스마트폰으로 QR코드를 스캔 합니다.",
  "target.view.testway.list1": "브라우저에서 카메라가 활성화 됩니다.",
  "target.view.testway.list2": "해당 이미지에 카메라를 비추어  AR Test가 확인되시면  마커 이미지  사용 가능합니다.",
  "target.view.testway.list3": "단, 인식에 성공하더라도 인식률이 떨어지거나 떨림 현상 발생 시 이미지 타겟으로써 부적합한 이미지일 수 있습니다.\n이때는 다른 이미지로 교체하거나 이미지 보정을 통해 안정성을 높이는 작업이 필요합니다.",
  "target.view.deleteimage": "이미지 삭제하기",
  "target.view.replaceTargetImage": "타겟 이미지 변경하기",
  "target.rating.one.noimage": "저장후 이미지 확인이 가능합니다.",
  "target.rating.congruence.pass": "마커 사용이 적합한 이미지입니다.",
  "target.rating.congruence.notpass": "마커 사용이 부적합한 이미지입니다.",
  "target.rating.modify.title": "Title",
  "target.rating.modify.placeholder": "파일이름이 들어갑니다.",
  "target.rating.ratingtable.td0": "Image",
  "target.rating.ratingtable.td1": "Title",
  "target.rating.ratingtable.td2": "Rating",
  "target.rating.ratingtable.td3": "적합도",
  "target.rating.ratingtable.td4": "Width\n(millimeter)",
  "target.rating.ratingtable.td5": "삭제",
  "target.rating.one_congruence.pass": "마커 사용이 적합한 이미지입니다.",
  "target.rating.one_congruence.notpass": "마커 사용이 부적합한 이미지입니다.",
  "target.rating.multi_congruence.pass": "적합",
  "target.rating.multi_congruence.notpass": "부적합",
  "target.rating.deleteitem": "삭제",
  "target.targetlist.title": "Target list",
  "target.targetlist.allcount": "총 {{allCount}}개",
  "target.search.placeholder": "타겟 이미지 검색",

  // 2차 추가분
  "target.addtarget.width": "Width (millimeter)",
  "target.modaldelete.title": "이미지 삭제",
  "target.modaldelete.description": "이미지 삭제 후 복구하실 수 없습니다.",
  "target.targetlist.emptytext": "등록된 이미지가 없습니다.",
  "payment_history.emptytext": "결제된 내역이 없습니다.",

  //3차 추가분
  "target.addtarget.errorWidthReg": "숫자만 입력가능합니다.",
  "target.addtarget.errorWidthEmpty": "width값을 입력해주세요.",
  "paymenthistory.view_detail_mobile": "보기",
  "paymenthistory.view_detail_moretablet": "청구서 상세보기",
  "paymenthistory.search_period": "이용기간",
  "paymenthistory.search.all": "전체",
  "target.view.modal_copy_success": "링크 복사가 완료되었습니다.",
  "license.downgrade_notice": "{{downgradeDate}}에 다운그레이드 될 예정입니다.",
  "license.free_notice": "{{downgradeDate}}에 프로젝트의 뷰카운트가 초기화될 예정입니다.",

  //4차 추가분
  "dashboard.pvchart.error_period_title.over_max": "최대 조회 가능 기간은 {{maxRange}}년입니다.",
  "dashboard.pvchart.error_period_title.wrong_selected": "지정일을 수정해 주세요.",
  "license.heading_preparing_downgrade": "다운그레이드는 서비스 준비중입니다.",

  //추가분 이후
  "target.view.modal_title_update_success": "타겟 이미지 타이틀이 수정되었습니다.",
  "sort.updated": "업데이트 순",
  "sort.created": "생성일 순",
  "sort.highPv": "높은 PV 순",
  "sort.projectName": "프로젝트명 순",
  "sidebar.tutorial.link": "https://letsee14.notion.site/Letsee-WebAR-SDK-970d6621cbec487f8c1f7488a5ca86a3",
  "openBeta.title.01": "렛시 크리레이터 BETA",
  "openBeta.title.02": "",
  "openBeta.title.03": "무료",
  "openBeta.title.04": "로 사용해보세요",
  "openBeta.texts.01": "오픈베타 기간동안 렛시크리에이터를 무료로 사용할 수 있습니다.\n최대 3개의 프로젝트를 무료로 만들어 배포할 수 있습니다.",
  "openBeta.texts.02": "구체적인 가격정책은 오픈베타 기간동안의 피드백을 반영하여 추후 공개될 예정입니다.",
  "openBeta.popup.01": "오늘 하루 열지않기",
  "openBeta.popup.02": "더 이상 열지않기",
  "fbxToGlb.header.01": "FBX 파일을 GLB 파일로 변환하기",
  "fbxToGlb.header.02": "FBX 로 만들어진 3d 파일을 렛시 WebAR에 사용하기 위한 GLB 파일 형식으로 변환합니다. 권장 파일 사이즈는 10mb 이하입니다.",
  "fbxToGlb.start.01": "GLB 변환 시작",
  "fbxToGlb.dropbox.01": "Drag & Drop your FBX file here\n(file below 10mb)",
  "fbxToGlb.dropbox.02": "또는",
  "fbxToGlb.dropbox.03": "이곳을 클릭해 FBX 파일을 업로드 하세요.\n(파일 크기는 10mb 이내)",
  "fbxToGlb.progress.01": "FBX 파일 등록중...(1/3)",
  "fbxToGlb.progress.02": "GLB로 변환중...(2/3)",
  "fbxToGlb.progress.03": "다운로드 준비중...(3/3)",
  "fbxToGlb.progress.complete": "변환이 완료되었습니다.",
  "fbxToGlb.progress.fail": "죄송합니다, 다시 시도해주세요.",
  "fbxToGlb.progress.fail.button": "다른 파일로 다시 시도하기",
  "fbxToGlb.finish.download": "GLB 파일 내려받기",
  "fbxToGlb.finish.again": "다른 파일 이어서 변환하기",
};
