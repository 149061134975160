import {
  INIT_TARGET_RATING,
  SET_TARGET_PREDATA,
  GET_TARGET_RATING_REQUEST,
  GET_TARGET_RATING_SUCCESS,
  GET_TARGET_RATING_ERROR,
  DELETE_TARGET_RATING_ITEM,
  UPDATE_TARGET_RATING_PAGE,
  UPLOAD_TARGET_RATING_REQUEST,
  UPLOAD_TARGET_RATING_SUCCESS,
  UPLOAD_TARGET_RATING_ERROR
} from '../actions';

const initialState = {
  ratingList: [],
  ratingFiles: [],
  ratingListCurrentPage: 1,
  ratingListViewPerPage: 8,
  uploadResult: false,
  isLoading: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_TARGET_RATING:
      return initialState
    case SET_TARGET_PREDATA:
      return {
        ...state,
        ratingList: action.result.ratingList.concat(state.ratingList),
        ratingFiles: action.result.ratingFiles.concat(state.ratingFiles),
        ratingListCurrentPage: 1
      }
    case GET_TARGET_RATING_REQUEST:
      return {...state, isLoading: true, error: ''};
    case GET_TARGET_RATING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        ratingList: state.ratingList.map((el, index) => {
          if(action.result.length > index) {
            return {...el, ...action.result[index]}
          } else {
            return el
          }
        })
      };
    case GET_TARGET_RATING_ERROR:
      return {...state, isLoading: false, error: action.error}
    case DELETE_TARGET_RATING_ITEM:
      const deleteItemIndex = state.ratingList.indexOf(action.payload.deleteItem)
      return {
        ...state,
        ratingList: state.ratingList.filter(item => item !== action.payload.deleteItem),
        ratingFiles: [...state.ratingFiles.slice(0, deleteItemIndex), ...state.ratingFiles.slice(deleteItemIndex + 1)],
        ratingListCurrentPage: state.ratingList.length % state.ratingListViewPerPage === 1 && ((state.ratingList.length / state.ratingListViewPerPage) < state.ratingListCurrentPage) ? state.ratingListCurrentPage - 1 : state.ratingListCurrentPage
      }
    case UPDATE_TARGET_RATING_PAGE:
      return {...state, ...action.payload}
    case UPLOAD_TARGET_RATING_REQUEST:
      return {...state, isLoading: true, error: ''}
    case UPLOAD_TARGET_RATING_SUCCESS:
      return {...state, isLoading: false, error: '', uploadResult: true}
    case UPLOAD_TARGET_RATING_ERROR:
      return {...state, isLoading: false, error: action.error}
    default:
      return {...state};
  }
}