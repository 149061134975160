import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_EDITOR_PROJECT_REQUEST,
  GET_LIST_EDITOR_PROJECT_REQUEST,
  GET_EDITOR_PROJECT_REQUEST,
  PUT_EDITOR_PROJECT_REQUEST,
} from '../actions';

import {
  initEditorProject,
  addEditorProjectSuccess,
  addEditorProjectError,
  getListEditorProjectSuccess,
  getListEditorProjectError,
  getEditorProjectSuccess,
  getEditorProjectError,
  putEditorProjectSuccess,
  putEditorProjectError,
} from './actions';
import {
  createEditorProject,
  getListEditorProject,
  getEditorProject,
  putEditorProject
} from "../../api/editorProjects";
import {getEditorUrl, viewErrorByEnv} from "../../helpers/Utils";
import {createProjectPayment} from "../../api/projectPayment";

export function* watchAddEditorProject() {
  yield takeEvery(ADD_EDITOR_PROJECT_REQUEST, addEditorProjectWithValuesSaga);
}

const addEditorProjectAsync = async (values) => {
  let createInfo = {
    "title": values.title,
    "imageId": values.imageId,
  };
  /**
   * 프로젝트 생성하는 비동기 axios 함수.
   *
   * 1. LD 프로젝트 레코드를 생성하는 API 호출
   * 2. 프로젝트 생성후 projectPayment, paymentHistory 레코드를 생성하는 API 호출
   */
  const editorProjectResponse = await createEditorProject(createInfo)
  // 프로젝트 생성이 성공했을시 projectPayment, paymentHistory 레코드를 생성하는 API 호출.
  let Project = {};
  if (editorProjectResponse.data.success) {
    let data = {
      project_id: editorProjectResponse.data.project.id,
      payment_id: editorProjectResponse.data.project.paymentid,
      app_id: editorProjectResponse.data.project.appId,
    };
    
    const projectPaymentResponse = await createProjectPayment(data);
    if(projectPaymentResponse.data.success) {
      Project.message = false;
      Object.assign(Project, editorProjectResponse.data.project)
    } else {
      Project.message = editorProjectResponse.data.error;
    }
  } else {
    Project.message = editorProjectResponse.data.error;
  }

  return Project;
};

function* addEditorProjectWithValuesSaga({ payload }) {
  const { project } = payload;
  try {
    const Project = yield call(addEditorProjectAsync, project);
    if (!Project.message) {
      Project.viewcount = 0
      Project.maxviewcount = 1000
      yield put(addEditorProjectSuccess(Project));
      setTimeout(() => {
        const a = document.createElement('a');
        a.href = `${getEditorUrl(Project.id)}`
        document.body.appendChild(a);
        a.click()
      }, 200)
    } else {
      yield put(addEditorProjectError(Project.message));

    }
  } catch (error) {
    if(error.response.data) {
      yield put(addEditorProjectError(error.response.data.error))
    } else {
      viewErrorByEnv(error)
    }
  }
}

export function* watchGetListEditorProject() {
  yield takeEvery(GET_LIST_EDITOR_PROJECT_REQUEST, getListEditorProjectSaga);
}

const getListEditorProjectAsync = async () => {
  const response = await getListEditorProject()
  let Projects = {};
  if(response.data.success) {
    Projects.message = false;
    Projects.projects = response.data.projects;
  } else {
    Projects.message = response.data.error;
  }
  return Projects
};

function* getListEditorProjectSaga({ payload }) {
  try {
    const Projects = yield call(getListEditorProjectAsync);
    yield put(getListEditorProjectSuccess(Projects.projects, Projects.message, payload?.sort));
  } catch (error) {
    if(error.response.data) {
      yield put(getListEditorProjectError(error.response.data.error))
    } else {
      viewErrorByEnv(error)
    }

  }
}

export function* watchGetEditorProjectById() {
  yield takeEvery(GET_EDITOR_PROJECT_REQUEST, getEditorProjectByIdSaga);
}

const getEditorProjectByIdAsync = async (projectId) => {
  const response = await getEditorProject(projectId)
  let Project = {};
  if(response.data.success) {
    response.success ? Project.message = false : Project.message = response.error;
    Project.project = response.data.project;
  } else {
    Project.message = response.data.error;
  }
  return Project
};

function* getEditorProjectByIdSaga({ payload }) {
  const { projectId, history } = payload;
  const user_id = JSON.parse(sessionStorage.getItem("userinfo")).user_id

  try {
    const Project = yield call(getEditorProjectByIdAsync, projectId);
    if (!Project.message) {
      if (Project.project.users.find(x => {
        return x.user !== user_id || x.permissions.includes('pendding')})
      ) {
        yield put(initEditorProject())
        yield put(getEditorProjectError('permissionError'));
        history.push('/')
      } else {
        yield put(getEditorProjectSuccess(Project.project));
      }
    } else {
      yield put(initEditorProject())
      yield put(getEditorProjectError(Project.message));
      history.push('/')
    }
  } catch (error) {
    yield put(initEditorProject())
    if(error.response.data) {
      yield put(getEditorProjectError(error.response.data.error));
    } else {
      viewErrorByEnv(error)
    }
    history.push('/')
  }
}

export function* watchPutEditorProject() {
  yield takeEvery(PUT_EDITOR_PROJECT_REQUEST, putEditorProjectSaga);
}

const putEditorProjectAsync = async (projectInfo) => {
  let project = {};
    const response = await putEditorProject(projectInfo)
    if(response.data.success) {
      project.project = response.data.project;
      project.message = false
    } else {
      project.message = response.data.error;
    }
  return project;
};

function* putEditorProjectSaga({payload}) {
  const {project} = payload
  try {
    const result = yield call(putEditorProjectAsync, project);
    if (!result.message) {
      yield put(putEditorProjectSuccess(result.project));
    } else {
      yield put(putEditorProjectError(result.message));
    }
  } catch (error) {
    if(error.response.data) {
      yield put(putEditorProjectError(error.response.data.error));
    } else {
      viewErrorByEnv(error)
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAddEditorProject),
    fork(watchGetListEditorProject),
    fork(watchGetEditorProjectById),
    fork(watchPutEditorProject),
  ]);
}
